import React from "react";
import { Link } from "react-router-dom";

const Logo = ({
  onClick,
  className = "hidden",
}: {
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <Link
      className={`text-blue-500 md:inline-flex items-center ${className}`}
      to="/"
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        className="h-7 w-7"
        fill="#3b83f6"
      >
        <path d="M24 45.15q-4 0-7.45-1.3t-6-3.5Q8 38.15 6.575 35.3 5.15 32.45 5.15 29.4v-5.6l8.9 6.7-3.1 3.1q1.5 2.5 4.575 4.45Q18.6 40 21.65 40.2V22.95h-6.5v-4.7h6.5v-2.9q-2.3-1-3.425-2.675Q17.1 11 17.1 8.95q0-2.95 2.025-4.975Q21.15 1.95 24 1.95q2.9 0 4.925 2.025T30.95 8.95q0 2.05-1.15 3.725-1.15 1.675-3.45 2.675v2.9h6.5v4.7h-6.5V40.2q3.05-.2 6.125-2.15t4.575-4.45l-3.1-3.1 8.9-6.7v5.6q0 3.05-1.425 5.9Q40 38.15 37.45 40.35q-2.55 2.2-6 3.5-3.45 1.3-7.45 1.3Zm0-34q.95 0 1.575-.675T26.2 8.95q0-.95-.65-1.6Q24.9 6.7 24 6.7t-1.55.65q-.65.65-.65 1.6 0 .9.675 1.55t1.525.65Z" />
      </svg>
      <div className="ml-2 tracking-tight font-medium text-3xl text-transparent bg-clip-text bg-gradient-to-r to-cyan-500 from-blue-500">
        docking
      </div>
    </Link>
  );
};

export default Logo;
