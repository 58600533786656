import { type ReactElement } from "react";

const AppToc = ({
  children,
  title,
  className,
}: {
  children: ReactElement | ReactElement[];
  title: string;
  className: string;
}) => (
  <div className={`w-full lg:w-1/5 block relative ${className}`}>
    <div className="lg:fixed lg:pt-16 overflow-y-auto h-full lg:h-screen">
      <div className="py-4 lg:py-4 px-2 lg:px-4">
        <p className="mb-3 lg:mb-2 text-gray-500 uppercase tracking-wider font-bold text-sm lg:text-xs">
          {title}
        </p>
        {children}
      </div>
    </div>
  </div>
);

export default AppToc;
