import { useTranslation } from "react-i18next";
import { Variant } from "../types/components";
import { type SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { mainApi } from "../services/api/main";
import errorParser from "../utils/errorParser";
import { type FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useAppDispatch, useAppSelector } from "../store";
import { selectToken, setToken } from "../store/members";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  DefaultLabel,
  DefaultButton,
  DefaultInput,
  Install,
} from "../components";
import Logo from "../components/logo";

interface LoginFormValues {
  email: string;
  password: string;
}

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const token = useAppSelector(selectToken);

  React.useEffect(() => {
    if (token) {
      navigate("/ports");
    }
  }, [token, navigate]);

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginFormValues>();
  const [login, { isLoading }] = mainApi.useLoginMutation();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<LoginFormValues> = async (data) => {
    try {
      const resp = await login(data).unwrap();
      dispatch(setToken(resp.key));
    } catch (e) {
      errorParser(e as FetchBaseQueryError).forEach((e) => toast.error(e));
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col justify-center">
        <div className="mx-auto w-full md:max-w-md px-4 md:px-0">
          <Logo className="inline-flex mb-4" />
          <Install />
          <div className="mt-4 bg-white border dark:bg-gray-900 dark:border-gray-800 w-full rounded-lg divide-y divide-gray-200 dark:divide-gray-800 shadow-sm">
            <form className="px-5 py-7" onSubmit={handleSubmit(onSubmit)}>
              <DefaultLabel htmlFor="email" className="mb-2">
                {t("login.email")}
              </DefaultLabel>
              <DefaultInput
                placeholder={t("login.enterEmail")}
                id="email"
                type="email"
                {...register("email", { required: true })}
              />

              <DefaultLabel htmlFor="password" className="mt-6 mb-2">
                {t("login.password")}
              </DefaultLabel>
              <DefaultInput
                placeholder={t("login.enterPassword")}
                id="password"
                type="password"
                {...register("password", { required: true })}
              />

              <DefaultButton
                value={t("login.login")}
                loading={isLoading}
                variant={Variant.primary}
                className="mt-6 w-full"
                type="submit"
                disabled={!isValid}
              />
            </form>
            <div className="hidden p-5">
              <DefaultButton
                variant={Variant.outline}
                className="w-full"
                loading={false}
                disabled={false}
                value={t("login.register")}
                onClick={() => {
                  navigate("/register");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
