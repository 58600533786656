import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../types/store";

export interface MembersState {
  token: string;
}

const initialState: MembersState = {
  token: "",
};

const membersSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
  },
});

export const { setToken } = membersSlice.actions;

export const selectToken = (state: RootState) =>
  state[membersSlice.name].token;

export default membersSlice;
