import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { Toaster } from "react-hot-toast";
import i18next from "i18next";
import { persistor, store } from "./store";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Index from "./pages";
import Login from "./pages/login";
import IndexAccount from "./pages/ports";
import AccountLayout from "./layouts/account";
import IndexPlaces from "./pages/ports/places/[placePk]/markers";
import IndexSchedules from "./pages/ports/places/[placePk]/markers/[markerPk]/schedules";
import DetailSchedule from "./pages/ports/places/[placePk]/markers/[markerPk]/schedules/[schedulePk]/index";
import Error from "./pages/error";
import "react-tooltip/dist/react-tooltip.css";
import { TooltipProvider } from "react-tooltip";
import IndexBookings from "./pages/ports/bookings";
import IndexSettings from "./pages/ports/settings";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/ports",
    element: (
      <AccountLayout>
        <IndexAccount />
      </AccountLayout>
    ),
  },
  {
    path: "/ports/places/:placePk/markers",
    element: (
      <AccountLayout>
        <IndexPlaces />
      </AccountLayout>
    ),
  },
  {
    path: "/ports/places/:placePk/markers/:markerPk/schedules",
    element: (
      <AccountLayout>
        <IndexSchedules />
      </AccountLayout>
    ),
  },
  {
    path: "/ports/places/:placePk/markers/:markerPk/schedules/:schedulePk",
    element: (
      <AccountLayout>
        <DetailSchedule />
      </AccountLayout>
    ),
  },
  {
    path: "/ports/places/:placePk/bookings",
    element: (
      <AccountLayout>
        <IndexBookings />
      </AccountLayout>
    ),
  },
  {
    path: "/ports/places/:placePk/settings",
    element: (
      <AccountLayout>
        <IndexSettings />
      </AccountLayout>
    ),
  },
]);

const App = () => {
  React.useEffect(() => {
    let { language } = navigator;
    if (navigator.languages !== undefined) {
      language = navigator.languages[0];
    }

    i18next.use(initReactI18next).init({
      returnNull: false,
      lng: language.split("-")[0],
      debug: false,
      resources: {
        en: {
          translation: require("./locales/en/common.json"),
        },
        es: {
          translation: require("./locales/es/common.json"),
        },
      },
      fallbackLng: "es",
    });
  });

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <I18nextProvider i18n={i18next}>
          <TooltipProvider>
            <RouterProvider router={router} />
          </TooltipProvider>
          <Toaster position="top-center" />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
