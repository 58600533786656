import React from "react";
import { type ButtonHTMLAttributes } from "react";
import IconLoad from "./icons/iconLoad";
import { Size, Variant } from "../types/components";

const DefaultButton = (
  props: ButtonHTMLAttributes<HTMLButtonElement> & {
    loading?: boolean;
    variant: Variant;
    size?: Size;
  }
) => {
  const { variant, size = Size.md, loading, ...restProps } = props;

  const classes = React.useMemo(() => {
    switch (variant) {
      case Variant.primary:
        return `
              bg-blue-500
              hover:bg-blue-600
              focus:bg-blue-700
              focus:shadow-sm
              focus:ring-4
              focus:ring-blue-500
              focus:ring-opacity-50
              text-white
              font-semibold
            `;
      case Variant.outline:
        return `
              border
              border-gray-300
              dark:border-gray-800
              text-gray-500
              dark:text-gray-300
              font-normal
            `;
      case Variant.red:
        return `
              bg-red-500
              hover:bg-red-600
              focus:bg-red-700
              focus:shadow-sm
              focus:ring-4
              focus:ring-red-500
              focus:ring-opacity-50
              text-white
              font-semibold
          `;
      case Variant.green:
        return `
              bg-green-500
              hover:bg-green-600
              focus:bg-green-700
              focus:shadow-sm
              focus:ring-4
              focus:ring-green-500
              focus:ring-opacity-50
              text-white
              font-semibold
          `;
      case Variant.outlineRed:
        return `
            border
            border-red-500
            dark:border-red-600
            text-red-500
            dark:text-red-500
            font-normal
          `;
      default:
        return "";
    }
  }, [variant]);

  const sizes = React.useMemo(() => {
    switch (size) {
      case Size.sm:
        return "px-3 py-2 text-xs";
      case Size.md:
        return "px-4 py-2.5 text-sm";
      case Size.lg:
        return "px-6 py-4.5 text-md";
    }
  }, [size]);

  return (
    <button
      {...restProps}
      className={`
      relative
      text-center
      inline-block
      justify-center
      rounded-lg
      shadow-sm
      hover:shadow-md
      transition
      duration-200
      ${sizes}
      ${classes}
      ${props.className}
      ${props.disabled ? "opacity-40" : ""}
    `}
    >
      <div
        className={`absolute left-0 ml-2 w-full flex justify-center items-center ${
          loading ? "opacity-1" : "opacity-0"
        }`}
      >
        <IconLoad className="-ml-1 mr-2" />
      </div>
      <span
        className={`whitespace-nowrap inline-block ${
          loading ? "opacity-0" : "opacity-1"
        }`}
      >
        {props.value}
      </span>
    </button>
  );
};

export default DefaultButton;
