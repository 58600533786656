import { combineReducers, configureStore } from "@reduxjs/toolkit";
import membersSlice from "./members";
import { mainApi } from "../services/api/main";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { type AppDispatch, type RootState } from "../types/store";
import { rtkQueryErrorLogger } from "../utils/errorLogger";
import uiSlice from "./ui";

const rootReducer = combineReducers({
  [uiSlice.name]: uiSlice.reducer,
  [membersSlice.name]: membersSlice.reducer,

  [mainApi.reducerPath]: mainApi.reducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  whitelist: [membersSlice.name],
  blacklist: [uiSlice.name],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([mainApi.middleware, rtkQueryErrorLogger]),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
