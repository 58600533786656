import React, { type InputHTMLAttributes } from "react";

const DefaultSelect = (
  props: InputHTMLAttributes<HTMLSelectElement> & {
    options: Array<{ name: string; value: string }>;
  },
  ref: any
) => (
  <select
    ref={ref}
    {...props}
    className={`truncate leading-5 placeholder-gray-500 border border-transparent text-gray-700 dark:text-white dark-focus:text-white focus:border-gray-300 dark-focus:border-gray-800 rounded-md focus:outline-none focus:bg-white dark-focus:bg-gray-900 bg-gray-50 dark:bg-gray-800 px-3 py-2 w-full ${
      props.className ?? ""
    }`}
  >
    {props.options.map((i) => (
      <option key={i.value} value={i.value}>
        {i.name}
      </option>
    ))}
  </select>
);

export default React.forwardRef(DefaultSelect);
