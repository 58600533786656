export enum Variant {
  primary = "primary",
  outline = "outline",
  red = "red",
  green = "green",
  outlineRed = "outlineRed",
}

export enum Size {
  sm = "small",
  md = "medium",
  lg = "large",
}
