import React from "react";
import { Variant } from "../../types/components";
import { useTranslation } from "react-i18next";
import { type SubmitHandler, useForm } from "react-hook-form";
import { mainApi } from "../../services/api/main";
import errorParser from "../../utils/errorParser";
import { type FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  IconLoad,
  DefaultLabel,
  DefaultButton,
  DefaultInput,
  Modal,
  AppPage,
  AppHeader,
  DefaultPriceInputTable,
} from "../../components";
import { Place } from "../../types/services/main";
import { useModal } from "../../utils/modal";
import currency from "currency.js";

interface CreateStorageFormValues {
  name: string;
  country: string;
  city: string;
  taxRate: string;
}

const Index = () => {
  const { t } = useTranslation("translation");
  const [showCreate, setShowCreate] = useModal();
  const [createPlace, { isLoading: isCreatingPlace }] =
    mainApi.useCreatePlaceMutation();
  const { data, isLoading } = mainApi.useListPlacesQuery(undefined);
  const places = React.useMemo(() => data?.results ?? [], [data?.results]);
  const defaultPrices = [{ length: "0", monohull: "0", catamaran: "0" }];
  const [prices, setPrices] = React.useState<any[]>(defaultPrices);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");

  React.useEffect(() => {
    if (places.length === 1 && redirect !== "no") {
      navigate(`/ports/places/${places[0].pk}/markers`);
    }
  }, [places, redirect, navigate, showCreate]);

  const { register, handleSubmit, reset } = useForm<CreateStorageFormValues>();

  const toggleShowCreate = () => {
    setShowCreate(!showCreate);
  };
  const onSubmit: SubmitHandler<CreateStorageFormValues> = async ({
    name,
    taxRate,
  }) => {
    try {
      await createPlace({
        name,
        taxRate: currency(taxRate).multiply(100).value,
        prices: prices.map((i) => ({
          length: parseInt(i.length),
          monohull: currency(i.monohull).multiply(100).value,
          catamaran: currency(i.catamaran).multiply(100).value,
        })),
      }).unwrap();
      toggleShowCreate();
      reset();
      setPrices(defaultPrices);
    } catch (e) {
      errorParser(e as FetchBaseQueryError).forEach((i) => toast.error(i));
    }
  };

  return (
    <AppPage className="lg:w-full lg:border-l-0 lg:border-r-0 mx-auto pt-20">
      <AppHeader
        title={t("account.places")}
        onClickCreate={toggleShowCreate}
        buttonTitle={t("account.createPlace")}
      />
      <div>
        {!isLoading && places.length === 0 && (
          <div className="flex justify-center items-center mt-10 text-gray-500 text-center">
            {t("account.noPlaces")}
          </div>
        )}
        {isLoading && (
          <div className="w-full flex justify-center mt-10">
            <IconLoad />
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
        {places.map((i: Place) => (
          <Link
            key={i.pk}
            to={`/ports/places/${i.pk}/markers`}
            className={`flex flex-row items-center justify-start border border-gray-200 rounded-lg p-4 select-none`}
          >
            <div className="p-2 bg-gray-100 rounded-full">
              <div className="h-6 w-6 text-gray-900 text-2xl flex justify-center items-center font-bold uppercase">
                {i.name.slice(0, 1)}
              </div>
            </div>
            <div className="text-gray-800 px-2 py-1 rounded-lg ml-2">
              {i.name}
            </div>
          </Link>
        ))}
      </div>
      <Modal
        visible={showCreate}
        onRequestClose={() => {
          setShowCreate(false);
        }}
        className="w-full md:w-3/5 lg:w-2/5"
        title={t("account.newPlace")}
      >
        <div className="text-md text-gray-500">
          {t("account.createPlaceMessage")}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DefaultLabel htmlFor="name" className="mt-6 mb-2">
            {t("account.name")}
          </DefaultLabel>
          <DefaultInput
            placeholder={t("account.enterName")}
            id="name"
            type="text"
            {...register("name", { required: true })}
          />
          <DefaultLabel htmlFor="taxRate" className="mt-2 mb-2">
            {t("account.taxRate")}
          </DefaultLabel>
          <DefaultInput
            placeholder={t("account.enterTaxRate")}
            id="taxRate"
            type="number"
            step="0.01"
            {...register("taxRate", { required: true })}
          />
          <DefaultPriceInputTable prices={prices} setPrices={setPrices} />
          {/* <DefaultLabel htmlFor="country" className="mt-6 mb-2"> */}
          {/*  {t('account.country')} */}
          {/* </DefaultLabel> */}
          {/* <DefaultInput */}
          {/*  placeholder={t('account.enterCountry')} */}
          {/*  id="country" */}
          {/*  type="text" */}
          {/*  {...register('country', { required: true })} */}
          {/* /> */}
          {/* <DefaultLabel htmlFor="city" className="mt-6 mb-2"> */}
          {/*  {t('account.city')} */}
          {/* </DefaultLabel> */}
          {/* <DefaultInput */}
          {/*  placeholder={t('account.enterCity')} */}
          {/*  id="name" */}
          {/*  type="text" */}
          {/*  {...register('city', { required: true })} */}
          {/* /> */}
          <div className="flex flex-row justify-end items-center mt-14">
            <DefaultButton
              value={t("account.createPlace")}
              variant={Variant.primary}
              className="ml-4"
              type="submit"
              loading={isCreatingPlace}
            />
          </div>
        </form>
      </Modal>
    </AppPage>
  );
};

export default Index;
