import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { type RootState } from "../types/store";

export interface UIState {
  markerFilters: {
    show: boolean;
    length?: number;
    width?: number;
    depth?: number;
    startDatetime?: string;
    endDatetime?: string;
    available?: "true" | "false" | "";
  };
}

const initialState: UIState = {
  markerFilters: {
    show: false,
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setMarkerFilters(state, action: PayloadAction<UIState["markerFilters"]>) {
      state.markerFilters = action.payload;
    },
  },
});

export const { setMarkerFilters } = uiSlice.actions;

export const selectMarkerFilters = (state: RootState) =>
  state[uiSlice.name].markerFilters;

export default uiSlice;
