import React from "react";
import { useNavigate } from "react-router-dom";

const Error = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate("/");
  });

  return null;
};

export default Error;
