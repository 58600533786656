import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "./modal";
import DefaultLabel from "./defaultLabel";
import DefaultInput from "./defaultInput";
import DefaultButton from "./defaultButton";
import { Variant } from "../types/components";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import IconMore from "./icons/iconMore";
import IconLess from "./icons/iconLess";
import { useFormatDate } from "../utils/hooks";
import { formatISO, parseISO } from "date-fns";
import { Calendar } from "react-calendar";
import { useModal } from "../utils/modal";

interface SearchMarkerValues {
  length: number;
  width: number;
  depth: number;
  dateRange: [Date, Date];
  hourStart: string;
  hourEnd: string;
}

const Filter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation("translation");
  const formatDate = useFormatDate();
  const [showMarkerFilters, setShowMarkerFilters] = useModal();
  const toggleShowMarkerFilters = () => {
    setShowMarkerFilters(!showMarkerFilters);
  };
  const [showDates, setShowDates] = React.useState(true);
  const [showDimensions, setShowDimensions] = React.useState(true);

  const filters = {
    length__gte: searchParams.get("length__gte") ?? "",
    width__gte: searchParams.get("width__gte") ?? "",
    depth__gte: searchParams.get("depth__gte") ?? "",
    start_datetime: searchParams.get("start_datetime") ?? "",
    end_datetime: searchParams.get("end_datetime") ?? "",
  };

  const defaultValues: any = {
    length: filters.length__gte ? parseFloat(filters.length__gte) : 0,
    width: filters.width__gte ? parseFloat(filters.width__gte) : 0,
    depth: filters.depth__gte ? parseFloat(filters.depth__gte) : 0,
    dateRange: filters.start_datetime
      ? [parseISO(filters.start_datetime), parseISO(filters.end_datetime)]
      : undefined,
    hourEnd: "23:59",
    hourStart: "00:00",
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { isValid },
  } = useForm<SearchMarkerValues>({
    defaultValues,
  });
  const dateRange = watch("dateRange") ?? [undefined, undefined];
  const dateRangeStart = dateRange[0];
  const dateRangeEnd = dateRange[1];
  const hourStart = watch("hourStart");
  const hourEnd = watch("hourEnd");

  const onSubmitSearchMarker = (data: SearchMarkerValues) => {
    const startDate = data.dateRange[0];
    const endDate = data.dateRange[1];

    const hourStart = data.hourStart.split(":");
    const hourEnd = data.hourEnd.split(":");
    startDate.setHours(parseInt(hourStart[0]), parseInt(hourStart[1]));
    endDate.setHours(parseInt(hourEnd[0]), parseInt(hourEnd[1]));

    setShowMarkerFilters(false);
    setTimeout(() => {
      setSearchParams({
        length__gte: `${data.length}`,
        width__gte: `${data.width}`,
        depth__gte: `${data.depth}`,
        start_datetime: formatISO(startDate),
        end_datetime: formatISO(endDate),
      });
    }, 10);
  };

  const displayFilters = [
    { name: t("account.length"), value: filters.length__gte },
    { name: t("account.width"), value: filters.width__gte },
    { name: t("account.depth"), value: filters.depth__gte },
  ].filter((i) => i.value);

  return (
    <>
      <div
        onClick={toggleShowMarkerFilters}
        className="transition-all duration-500 cursor-pointer w-full md:w-80 shadow-md hover:shadow-lg bg-white border-2 border-blue-500 rounded-full p-2 pr-4 flex flex-row items-center"
      >
        <div className="bg-blue-500 rounded-full p-2 mr-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="text-white h-5 w-5"
            fill="currentColor"
            viewBox="0 0 48 48"
          >
            <path d="M39.75 43.35 26.3 29.95q-1.45 1.15-3.425 1.8-1.975.65-4.275.65-5.75 0-9.75-4t-4-9.65q0-5.65 4-9.65t9.7-4q5.65 0 9.625 4 3.975 4 3.975 9.65 0 2.25-.625 4.175-.625 1.925-1.875 3.625l13.5 13.4ZM18.55 27.7q3.75 0 6.325-2.625t2.575-6.325q0-3.75-2.575-6.35Q22.3 9.8 18.55 9.8t-6.375 2.6Q9.55 15 9.55 18.75q0 3.7 2.625 6.325T18.55 27.7Z" />
          </svg>
        </div>
        <div className="">
          <div className="text-sm font-medium text-gray-800 truncate overflow-hidden">
            {filters.start_datetime
              ? `${formatDate(
                  parseISO(filters.start_datetime),
                  "d MMM, HH:mm"
                )} - ${formatDate(
                  parseISO(filters.end_datetime),
                  "d MMM, HH:mm"
                )}`
              : t("nextHours", { count: 24 })}
          </div>
          <div className="text-xs text-gray-400 truncate overflow-hidden">
            {displayFilters.length === 0
              ? t("account.allMarkers")
              : displayFilters.map((i) => `${i.name} ${i.value}+`).join(" / ")}
          </div>
        </div>
      </div>
      <Modal
        className="w-full md:w-3/5 lg:w-2/5 relative bg-gray-100"
        title={t("account.searchAvailability")}
        onRequestClose={() => {
          setShowMarkerFilters(false);
          reset(defaultValues);
        }}
        visible={showMarkerFilters}
      >
        <form onSubmit={handleSubmit(onSubmitSearchMarker)}>
          <div className="bg-white rounded-lg">
            <div
              className="p-4 flex flex-row justify-between items-center cursor-pointer"
              onClick={() => {
                setShowDates(!showDates);
              }}
            >
              <div className="text-md font-semibold inline-flex items-center">
                {t("dates")}{" "}
                <span className="ml-2 text-gray-400 text-md font-normal">
                  {dateRangeStart &&
                    `${formatDate(
                      dateRangeStart,
                      "d MMM"
                    )} ${hourStart} - ${formatDate(
                      dateRangeEnd,
                      "d MMM"
                    )} ${hourEnd}`}
                </span>
              </div>
              {showDates ? (
                <IconLess className="h-5 w-5" />
              ) : (
                <IconMore className="h-5 w-5" />
              )}
            </div>
            {showDates && (
              <div className="mt-4 p-4 pt-0">
                <Controller
                  name="dateRange"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Calendar onChange={onChange} value={value} selectRange />
                  )}
                ></Controller>
                <div className="flex flex-row items-center justify-between mt-4">
                  <div className="">
                    <DefaultLabel htmlFor="hourStart" className="">
                      {t("account.hourStart")}
                    </DefaultLabel>
                    <DefaultInput
                      placeholder={t("account.hourStart")}
                      id="hourStart"
                      type="time"
                      {...register("hourStart", { required: true })}
                    />
                  </div>

                  <div className="">
                    <DefaultLabel htmlFor="hourEnd" className="">
                      {t("account.hourEnd")}
                    </DefaultLabel>
                    <DefaultInput
                      placeholder={t("account.hourEnd")}
                      id="hourEnd"
                      type="time"
                      {...register("hourEnd", { required: true })}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="bg-white rounded-lg mt-4">
            <div
              className="p-4 flex flex-row justify-between items-center cursor-pointer"
              onClick={() => {
                setShowDimensions(!showDimensions);
              }}
            >
              <div className="text-md font-semibold">
                {t("account.minDimensions")}
              </div>
              {showDimensions ? (
                <IconLess className="h-5 w-5" />
              ) : (
                <IconMore className="h-5 w-5" />
              )}
            </div>
            {showDimensions && (
              <div className="mt-4 p-4 pt-0">
                <DefaultLabel htmlFor="length" className="mb-1">
                  {t("account.length")} ({t("account.metres")})
                </DefaultLabel>
                <DefaultInput
                  placeholder={t("account.length")}
                  id="length"
                  type="number"
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  {...register("length", { required: false })}
                />
              </div>
            )}
          </div>
          <div className="flex flex-row justify-between items-center mt-6 w-full left-0 px-4py-2">
            <DefaultButton
              value={t("reset")}
              variant={Variant.outline}
              onClick={() => {
                reset();
                setShowMarkerFilters(false);
                setTimeout(() => {
                  setSearchParams({});
                }, 10);
              }}
              type="button"
            />
            <DefaultButton
              value={t("account.filterMarkers")}
              variant={Variant.primary}
              className="ml-4"
              type="submit"
              disabled={!isValid}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Filter;
