import React from 'react';
import IconClose from './icons/iconClose';
import DefaultInput from './defaultInput';
import DefaultLabel from './defaultLabel';
import { useTranslation } from 'react-i18next';
import DefaultButton from './defaultButton';
import { Variant } from '../types/components';

interface Prices {length: string; monohull: string; catamaran: string;}

const DefaultPriceInputTable = ({prices, setPrices}: {prices: Prices[]; setPrices: (data: Prices[]) => void}) => {
  const {t} = useTranslation("translation")

  return (
    <div>
      <table className="table-auto w-full mt-6">
            <thead>
              <tr>
                <th rowSpan={2}>
                  <DefaultLabel htmlFor="name">
                    {t("account.priceLength")}
                  </DefaultLabel>
                </th>
                <th
                  colSpan={2}
                  className="border border-gray-100 border-t-0 border-r-0"
                >
                  <DefaultLabel htmlFor="name">
                    {t("account.priceDay")}
                  </DefaultLabel>
                </th>
                <th></th>
              </tr>
              <tr>
                <th className="border-l border-gray-100">
                  <DefaultLabel htmlFor="name" className="mt-2 mb-2">
                    {t("account.monohull")}
                  </DefaultLabel>
                </th>
                <th>
                  <DefaultLabel htmlFor="name" className="mt-2 mb-2">
                    {t("account.catamaran")}
                  </DefaultLabel>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {prices.map(
                (i, idx) =>
                  i && (
                    <tr key={idx}>
                      <td>
                        <DefaultInput
                          placeholder={t("account.length")}
                          id="priceMax"
                          type="number"
                          step="0.01"
                          value={prices[idx].length}
                          onChange={(e) => {
                            const p = [...prices];
                            p[idx].length = e.target.value;
                            setPrices(p);
                          }}
                          required
                        />
                      </td>
                      <td>
                        <DefaultInput
                          placeholder={t("account.price")}
                          id="priceMax"
                          type="number"
                          step="0.01"
                          value={prices[idx].monohull ?? ""}
                          onChange={(e) => {
                            const p = [...prices];
                            p[idx].monohull = e.target.value;
                            setPrices(p);
                          }}
                          required
                        />
                      </td>
                      <td>
                        <DefaultInput
                          placeholder={t("account.price")}
                          id="priceMax"
                          type="number"
                          step="0.01"
                          value={prices[idx].catamaran ?? ""}
                          onChange={(e) => {
                            const p = [...prices];
                            p[idx].catamaran = e.target.value;
                            setPrices(p);
                          }}
                          required
                        />
                      </td>
                      <td
                        onClick={() => {
                          const p = [...prices];
                          p[idx] = undefined as any;
                          setPrices(p.filter(Boolean));
                        }}
                      >
                        <IconClose className="h-6 w-6 cursor-pointer" />
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
          <DefaultButton
          variant={Variant.outline}
          value={t("account.addOneRow")}
          className="w-full mt-2"
          onClick={() => {
            setPrices([
              ...prices,
              { length: "", monohull: "", catamaran: "" },
            ]);
          }}
        />
    </div>
  )
}

export default DefaultPriceInputTable;
