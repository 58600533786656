import React, { type ReactNode } from "react";
import IconClose from "./icons/iconClose";
import { useEscapeKey } from "../utils/hooks";

const Modal = ({
  visible,
  children,
  onRequestClose,
  className,
  title,
}: {
  visible: boolean;
  children: ReactNode;
  onRequestClose: () => void;
  className?: string;
  title?: string;
}) => {
  useEscapeKey(() => {
    visible && onRequestClose();
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <div className="z-20 fixed top-0 left-0 bg-gray-900/80 md:backdrop-blur-sm h-screen w-full flex justify-center items-center">
      <div
        className={`h-screen md:h-auto m-0 max-h-screen overflow-y-auto bg-white w-full lg:w-4/5 rounded-none md:rounded-md p-4 md:m-2 ${
          className ?? ""
        }`}
      >
        <div className="flex flex-row justify-between items-center mb-4">
          <div className="font-medium text-xl">{title ?? ""}</div>
          <div
            className="cursor-pointer bg-white border border-gray-200 rounded-full p-2"
            onClick={onRequestClose}
          >
            <IconClose className="h-5 w-5" />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
