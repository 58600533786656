import React from "react";
import { useTranslation } from "react-i18next";
import DefaultButton from "./defaultButton";
import { Variant } from "../types/components";

const InstallModal = () => {
  const { t } = useTranslation("translation");

  const [deferredPrompt, setDeferredPrompt] = React.useState<any>();
  const installListener = (e: any) => {
    e.preventDefault();
    setDeferredPrompt(e);
  };

  const onInstall = () => {
    setTimeout(() => {
      deferredPrompt?.prompt();
    }, 10);
  };

  React.useEffect(() => {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      // it's running standalone mode already (in an app)
      return;
    }

    window.addEventListener("beforeinstallprompt", installListener);
    return () => {
      window.removeEventListener("beforeinstallprompt", installListener);
    };
  }, []);

  if (!deferredPrompt) {
    return null;
  }

  return (
    <div className="p-4 border border-gray-200 rounded-lg shadow-sm">
      <div className="mb-2 font-bold ">{t("installApp")}</div>
      <div className="mb-4 text-sm">{t("installAppMessage")}</div>
      <DefaultButton
        onClick={onInstall}
        value={t("installApp")}
        variant={Variant.primary}
      />
    </div>
  );
};

export default InstallModal;
