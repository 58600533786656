import { type SerializedError } from "@reduxjs/toolkit";
import { type FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";

export default (
  error?: FetchBaseQueryError | (SerializedError & { data?: unknown })
) => {
  if (error == null || !error.data) {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { detail, non_field_errors, ...other } = error.data as Record<
    string,
    string[] | string
  >;

  if (non_field_errors) {
    if (Array.isArray(non_field_errors)) {
      return non_field_errors;
    }

    if (isString(non_field_errors)) {
      return [non_field_errors];
    }

    return [];
  }

  if (other && !isEmpty(other)) {
    const errors: string[] = [];

    Object.keys(other).forEach((k: string) => {
      const item = other[k];
      if (Array.isArray(item)) {
        item.forEach((errorItem: any) => {
          if (Array.isArray(errorItem)) {
            errorItem.forEach((err) => {
              if (isString(err)) {
                errors.push(err);
              }
            });
          } else if (typeof errorItem === "object") {
            if ("message" in errorItem) {
              errors.push(errorItem.message);
            } // If it's an object with no key message, let's ignore it; we don't recognize the format.
          } else {
            errors.push(errorItem);
          }
        });
      } else if (isString(item)) {
        errors.push(item);
      }
    });
    return errors.slice(0, 4);
  }

  return isString(detail) ? [detail] : [];
};
