import { DivIcon } from "leaflet";

const iconAddLocation = new DivIcon({
  html: `<div class="flex justify-center items-center mt-0.5"><svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" fill="currentColor" viewBox="0 0 48 48"><path d="M24 23.5q1.45 0 2.475-1.025Q27.5 21.45 27.5 20q0-1.45-1.025-2.475Q25.45 16.5 24 16.5q-1.45 0-2.475 1.025Q20.5 18.55 20.5 20q0 1.45 1.025 2.475Q22.55 23.5 24 23.5ZM24 44q-8.05-6.85-12.025-12.725Q8 25.4 8 20.4q0-7.5 4.825-11.95Q17.65 4 24 4q6.35 0 11.175 4.45Q40 12.9 40 20.4q0 5-3.975 10.875T24 44Z"/></svg></div>`,
  iconSize: [28, 28],
  className:
    "bg-gray-600 rounded-full text-white border-2 border-white drop-shadow-md",
});
const iconAvailableLocation = new DivIcon({
  html: `<div class="flex justify-center items-center mt-0.5"><svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" fill="currentColor" viewBox="0 0 48 48"><path d="M24 45.15q-4 0-7.45-1.3t-6-3.5Q8 38.15 6.575 35.3 5.15 32.45 5.15 29.4v-5.6l8.9 6.7-3.1 3.1q1.5 2.5 4.575 4.45Q18.6 40 21.65 40.2V22.95h-6.5v-4.7h6.5v-2.9q-2.3-1-3.425-2.675Q17.1 11 17.1 8.95q0-2.95 2.025-4.975Q21.15 1.95 24 1.95q2.9 0 4.925 2.025T30.95 8.95q0 2.05-1.15 3.725-1.15 1.675-3.45 2.675v2.9h6.5v4.7h-6.5V40.2q3.05-.2 6.125-2.15t4.575-4.45l-3.1-3.1 8.9-6.7v5.6q0 3.05-1.425 5.9Q40 38.15 37.45 40.35q-2.55 2.2-6 3.5-3.45 1.3-7.45 1.3Zm0-34q.95 0 1.575-.675T26.2 8.95q0-.95-.65-1.6Q24.9 6.7 24 6.7t-1.55.65q-.65.65-.65 1.6 0 .9.675 1.55t1.525.65Z"/></svg></div>`,
  iconSize: [28, 28],
  className:
    "bg-green-600 rounded-full text-white border-2 border-white drop-shadow-md",
});
const iconUnavailableLocation = new DivIcon({
  html: `<div class="flex justify-center items-center mt-0.5"><svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" fill="currentColor" viewBox="0 0 48 48"><path d="M24 45.15q-4 0-7.45-1.3t-6-3.5Q8 38.15 6.575 35.3 5.15 32.45 5.15 29.4v-5.6l8.9 6.7-3.1 3.1q1.5 2.5 4.575 4.45Q18.6 40 21.65 40.2V22.95h-6.5v-4.7h6.5v-2.9q-2.3-1-3.425-2.675Q17.1 11 17.1 8.95q0-2.95 2.025-4.975Q21.15 1.95 24 1.95q2.9 0 4.925 2.025T30.95 8.95q0 2.05-1.15 3.725-1.15 1.675-3.45 2.675v2.9h6.5v4.7h-6.5V40.2q3.05-.2 6.125-2.15t4.575-4.45l-3.1-3.1 8.9-6.7v5.6q0 3.05-1.425 5.9Q40 38.15 37.45 40.35q-2.55 2.2-6 3.5-3.45 1.3-7.45 1.3Zm0-34q.95 0 1.575-.675T26.2 8.95q0-.95-.65-1.6Q24.9 6.7 24 6.7t-1.55.65q-.65.65-.65 1.6 0 .9.675 1.55t1.525.65Z"/></svg></div>`,
  iconSize: [28, 28],
  className:
    "bg-red-600 rounded-full text-white border-2 border-white drop-shadow-md",
});
const iconSelectedLocation = new DivIcon({
  html: `<div class="flex justify-center items-center mt-0.5"><svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" fill="currentColor" viewBox="0 0 48 48"><path d="M24 23.5q1.45 0 2.475-1.025Q27.5 21.45 27.5 20q0-1.45-1.025-2.475Q25.45 16.5 24 16.5q-1.45 0-2.475 1.025Q20.5 18.55 20.5 20q0 1.45 1.025 2.475Q22.55 23.5 24 23.5ZM24 44q-8.05-6.85-12.025-12.725Q8 25.4 8 20.4q0-7.5 4.825-11.95Q17.65 4 24 4q6.35 0 11.175 4.45Q40 12.9 40 20.4q0 5-3.975 10.875T24 44Z"/></svg></div>`,
  iconSize: [28, 28],
  className:
    "bg-blue-600 rounded-full text-white border-2 border-white drop-shadow-md",
});

export {
  iconAddLocation,
  iconAvailableLocation,
  iconSelectedLocation,
  iconUnavailableLocation,
};
