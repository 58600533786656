import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const useModal = (): [boolean, (visible: boolean) => void] => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [searchParams] = useSearchParams();
  const name = React.useMemo(
    () => Math.random().toString(36).slice(2, 20),
    []
  );

  const setVisible = (v: boolean): void => {
    if (!v && !hash) return;

    if (v) {
      navigate({ hash: `#${name}`, search: searchParams.toString() });
    } else {
      navigate(-1);
    }
  };

  return [hash.slice(1) === name, setVisible];
};

export { useModal };
