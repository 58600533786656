import React from "react";
import { Link } from "react-router-dom";
import { MarkerSchedule } from "../types/services/main";
import {
  endOfDay,
  intervalToDuration,
  parseISO,
  roundToNearestMinutes,
  startOfDay,
} from "date-fns";
import { useFormatDate, useFormatDuration } from "../utils/hooks";
import { useTranslation } from "react-i18next";

const CardSchedule = ({
  placePk,
  item,
  selectedDate,
}: {
  placePk: string;
  item: MarkerSchedule;
  selectedDate?: Date;
}) => {
  const formatDate = useFormatDate();
  const formatDuration = useFormatDuration();
  const { t } = useTranslation("translation");
  const startDatetime = parseISO(item.startDatetime);
  const endDatetime = parseISO(item.endDatetime);

  return (
    <Link
      to={`/ports/places/${placePk}/markers/${item.markerPk}/schedules/${item.pk}`}
      className="w-full rounded-lg border border-gray-200 flex flex-row justify-start items-center p-4 mb-4"
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-col justify-start items-start">
          <div className="">
            {item.vehicleName} - {item.registration}
          </div>
          <div className="text-sm text-gray-500 inline-flex items-center">
            <div>
              {`${formatDate(startDatetime)} -
            ${formatDate(endDatetime)} (${formatDuration(
                intervalToDuration({
                  start: startDatetime,
                  end: roundToNearestMinutes(endDatetime),
                })
              )}) ${
                selectedDate &&
                startOfDay(selectedDate) > startDatetime &&
                endOfDay(selectedDate) < endDatetime
                  ? `${t("account.allDay")} - `
                  : ""
              }`}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-end text-sm">
          <div
            className={`whitespace-nowrap text-white text-[10px] px-2 rounded ${
              item.paid ? "bg-green-600" : "bg-red-600"
            }`}
          >
            {t(item.paid ? "paid" : "unpaid")}
          </div>
          <div className="mt-1 text-gray-800 text-xs">{item.price / 100}€</div>
        </div>
      </div>
    </Link>
  );
};

export default CardSchedule;
