import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperItem from "./swiperItem";
import { Marker } from "../../types/services/main";
import "swiper/css";

const SwiperList = ({
  onSwiper,
  onPkChange,
  markers,
  onPkNavigate,
  onPkEntry,
}: {
  markers: Marker[];
  onPkChange: (pk: number) => void;
  onPkNavigate: (pk: number) => void;
  onSwiper: (s: any) => void;
  onPkEntry: (pk: number) => void;
}) => {
  return (
    <Swiper
      onSwiper={onSwiper}
      slidesPerView={1.4}
      onSlideChange={(s) => {
        onPkChange(markers[s.activeIndex].pk);
      }}
      breakpoints={{
        478: {
          slidesPerView: 2.4,
        },
        768: {
          slidesPerView: 3.4,
        },
        1024: {
          slidesPerView: 4.4,
        },
        1280: {
          slidesPerView: 5.4,
        },
        1440: {
          slidesPerView: 6.4,
        },
      }}
      spaceBetween={10}
      centeredSlides
    >
      {markers.map((i, idx) => (
        <SwiperSlide key={i.pk}>
          <SwiperItem
            item={i}
            index={idx}
            onNavigate={() => {
              onPkNavigate(i.pk);
            }}
            onEntry={() => {
              onPkEntry(i.pk);
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperList;
