import { type LabelHTMLAttributes } from "react";

const DefaultLabel = (props: LabelHTMLAttributes<HTMLLabelElement>) => (
  <label
    {...props}
    className={`font-semibold text-sm text-gray-600 dark:text-gray-300 pb-1 block ${props.className}`}
  />
);

export default DefaultLabel;
