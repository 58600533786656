import { mainApi } from "../../../../../../../services/api/main";
import { Variant } from "../../../../../../../types/components";
import React from "react";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";
import { type SubmitHandler, useForm } from "react-hook-form";
import { endOfDay, formatISO, startOfDay, parseISO } from "date-fns";
import errorParser from "../../../../../../../utils/errorParser";
import { type FetchBaseQueryError } from "@reduxjs/toolkit/query";
import toast from "react-hot-toast";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  AppPage,
  AppToc,
  DefaultButton,
  DefaultLabel,
  IconLoad,
  DefaultInput,
  Modal,
  AppHeader,
  CardSchedule,
} from "../../../../../../../components";

interface CreateScheduleFormValues {
  startDatetime: string;
  endDatetime: string;
  captainEmail: string;
  registration: string;
}

const Index = () => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { placePk, markerPk } = useParams();
  const [searchParams] = useSearchParams();
  const d = searchParams.get("date");
  const { data, isLoading } = mainApi.useRetrieveMarkerQuery(
    { placePk, pk: Number(markerPk) },
    { skip: !markerPk || !placePk }
  );
  const [selectedDate, setSelectedDate] = React.useState(
    d ? parseISO(d) : new Date()
  );
  const [createSchedule, { isLoading: isCreatingSchedule }] =
    mainApi.useCreateScheduleMutation();
  const { data: schedulesData, isFetching } = mainApi.useListSchedulesQuery({
    placePk,
    markerPk,
    start_datetime: formatISO(startOfDay(selectedDate)),
    end_datetime: formatISO(endOfDay(selectedDate)),
  });
  const schedules = schedulesData ?? [];

  const [showCreate, setShowCreate] = React.useState(false);

  const toggleShowCreate = () => {
    setShowCreate(!showCreate);
  };

  React.useEffect(() => {
    if (placePk && markerPk) {
      navigate(
        {
          pathname,
          search: `?${createSearchParams({
            date: formatISO(selectedDate),
          })}`,
        },
        { replace: true }
      );
    }
  }, [selectedDate, placePk, markerPk]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid: isValidSchedule },
  } = useForm<CreateScheduleFormValues>();

  const onSubmitCreateSchedule: SubmitHandler<
    CreateScheduleFormValues
  > = async (data) => {
    try {
      await createSchedule({ placePk, markerPk, ...data }).unwrap();
      toggleShowCreate();
      reset();
    } catch (e) {
      errorParser(e as FetchBaseQueryError).forEach((i) => toast.error(i));
    }
  };

  if (isLoading) {
    return (
      <AppPage>
        <div className="w-full flex justify-center">
          <IconLoad />
        </div>
      </AppPage>
    );
  }

  const Filters = ({ className }: { className?: string }) => (
    <AppToc title={t("account.filters")} className={className ?? ""}>
      <Calendar onClickDay={setSelectedDate} value={selectedDate} />
    </AppToc>
  );

  return (
    <div className="flex flex-wrap-reverse flex-1 mt-2">
      <AppPage className="lg:w-4/6">
        <AppHeader
          title={t("account.detailMarker")}
          description={`${t("account.length")}: ${data?.length} - ${t(
            "account.width"
          )}: ${data?.width} - ${t("account.depth")}: ${data?.depth}`}
          // onClickCreate={toggleShowCreate}
          // buttonTitle={t("account.addItem")}
        />

        <Filters className="block lg:hidden" />
        <div className="w-full lg:mt-4">
          {isFetching ? (
            <div className="w-full flex justify-center">
              <IconLoad />
            </div>
          ) : (
            <div>
              {[...schedules]
                .sort(
                  (a, b) =>
                    (new Date(a.startDatetime) as any) -
                    (new Date(b.startDatetime) as any)
                )
                .map((i) => (
                  <CardSchedule
                    key={i.pk}
                    item={i}
                    placePk={placePk!}
                    selectedDate={selectedDate}
                  />
                ))}
            </div>
          )}
          {!isFetching && schedules.length === 0 && (
            <div className="flex justify-center items-center mt-10 text-gray-500 text-center">
              {t("account.noItems")}
            </div>
          )}
        </div>
      </AppPage>
      <Filters className="hidden lg:block w-2/5" />
      <Modal
        visible={showCreate}
        onRequestClose={() => {
          setShowCreate(false);
          reset();
        }}
        className="w-full md:w-3/5 lg:w-2/5"
        title={t("account.newSchedule")}
      >
        <div className="text-md text-gray-500">
          {t("account.createScheduleMessage")}
        </div>
        <form onSubmit={handleSubmit(onSubmitCreateSchedule)}>
          <DefaultLabel htmlFor="startDatetime" className="mb-1 mt-2">
            {t("account.dateIn")}
          </DefaultLabel>
          <DefaultInput
            id="startDatetime"
            type="datetime-local"
            {...register("startDatetime", {})}
          />
          <DefaultLabel htmlFor="endDatetime" className="mb-1 mt-2">
            {t("account.dateOut")}
          </DefaultLabel>
          <DefaultInput
            id="endDatetime"
            type="datetime-local"
            {...register("endDatetime", {})}
          />
          <div className="flex flex-row justify-end items-center mt-14">
            <DefaultButton
              value={t("account.cancelSchedule")}
              variant={Variant.outline}
              onClick={toggleShowCreate}
              type="button"
            />
            <DefaultButton
              value={t("account.addItem")}
              variant={Variant.primary}
              disabled={!isValidSchedule}
              className="ml-4"
              type="submit"
              loading={isCreatingSchedule}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Index;
