import { type FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import type { RootState } from "../../types/store";

const fetchBaseQueryOptions: FetchBaseQueryArgs = {
  baseUrl: `${
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000"
      : "https://warehouseapi.csorin.com"
  }/api/v1/`,
  prepareHeaders(headers, { getState }) {
    const { token } = (getState() as RootState).members;
    if (token && token.length > 4) {
      headers.set("Authorization", `Token ${token}`);
    }

    return headers;
  },
};

export { fetchBaseQueryOptions };
