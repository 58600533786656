import { Variant } from "../types/components";
import DefaultButton from "./defaultButton";
import IconTrash from "./icons/iconTrash";

const AppHeader = ({
  onClickDelete,
  onClickCreate,
  title,
  description,
  buttonTitle,
}: {
  onClickDelete?: () => void;
  onClickCreate?: () => void;
  title?: string;
  description?: string;
  buttonTitle?: string;
}) => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center mb-4">
      <div className="md:mb-0 w-full">
        <div className="font-medium text-3xl flex flex-row items-center">
          {title}
          {onClickDelete ? (
            <div
              className="border border-gray-200 rounded-full p-2 shadow-sm hover:shadow-md ml-2 cursor-pointer"
              onClick={onClickDelete}
            >
              <IconTrash className="h-5 w-5" />
            </div>
          ) : null}
        </div>
        {description ? (
          <div className="text-sm text-gray-400">{description}</div>
        ) : null}
      </div>
      {onClickCreate ? (
        <DefaultButton
          value={buttonTitle}
          variant={Variant.outline}
          onClick={onClickCreate}
          className="w-full md:w-auto mt-4 md:mt-0"
        />
      ) : null}
    </div>
  );
};

export default AppHeader;
