import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchBaseQueryOptions } from "./options";
import {
  type Place,
  type Marker,
  type Pagination,
  type MarkerSchedule,
  type PlaceDocument,
  PlaceDiscount,
} from "../../types/services/main";
import { queryEncode } from "../../utils/url";

type QueryParam = string | string[] | undefined | number | number[];

// Define a service using a base URL and expected endpoints
export const mainApi = createApi({
  reducerPath: "mainApi",
  baseQuery: fetchBaseQuery({
    ...fetchBaseQueryOptions,
  }),
  tagTypes: ["members", "place", "markers", "schedules", "documents", "discounts"],
  endpoints: (builder) => ({
    login: builder.mutation<
      { key: string },
      { email: string; password: string }
    >({
      query: (body) => ({
        url: "members/login/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["members"],
    }),
    listPlaces: builder.query<Pagination<Place>, undefined>({
      query: () => ({
        url: "places/",
      }),
      providesTags: ["place"],
      keepUnusedDataFor: 600,
    }),
    retrievePlace: builder.query<Place, Pick<Place, "pk">>({
      query: ({ pk }) => ({
        url: `places/${pk}/`,
      }),
      providesTags: ["place"],
      keepUnusedDataFor: 600,
    }),
    createPlace: builder.mutation<Place, Partial<Place>>({
      query: (body) => ({
        url: "places/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["place"],
    }),
    updatePlace: builder.mutation<
      Place,
      Partial<Place> & { placePk: QueryParam; }
    >({
      query: ({ placePk, ...body }) => ({
        url: `places/${placePk}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["place"],
    }),
    listMarkers: builder.query<
      Marker[],
      {
        placePk: QueryParam;
        length__gte?: QueryParam;
        width__gte?: QueryParam;
        depth__gte?: QueryParam;
        available?: QueryParam;
        start_datetime?: QueryParam;
        end_datetime?: QueryParam;
      }
    >({
      query: ({ placePk, ...searchParams }) => ({
        url: `places/${placePk}/markers/?${queryEncode(searchParams)}`,
      }),
      providesTags: ["markers"],
    }),
    retrieveMarker: builder.query<
      Marker,
      Pick<Marker, "pk"> & { placePk: QueryParam }
    >({
      query: ({ pk, placePk }) => ({
        url: `places/${placePk}/markers/${pk}/`,
      }),
      providesTags: ["markers"],
    }),
    createMarker: builder.mutation<
      Marker,
      Partial<Marker> & { placePk: QueryParam }
    >({
      query: ({ placePk, ...body }) => ({
        url: `places/${placePk}/markers/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["markers"],
    }),
    updateMarker: builder.mutation<
      Marker,
      Partial<Marker> & { placePk: QueryParam; markerPk: QueryParam }
    >({
      query: ({ placePk, markerPk, ...body }) => ({
        url: `places/${placePk}/markers/${markerPk}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["markers"],
    }),
    destroyMarker: builder.mutation<
      Marker,
      { placePk: QueryParam; markerPk: QueryParam }
    >({
      query: ({ placePk, markerPk }) => ({
        url: `places/${placePk}/markers/${markerPk}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["markers"],
    }),
    listSchedules: builder.query<
      MarkerSchedule[],
      {
        placePk: QueryParam;
        markerPk: QueryParam;
        start_datetime: QueryParam;
        end_datetime: QueryParam;
      }
    >({
      query: ({ placePk, markerPk, ...searchParams }) => ({
        url: `places/${placePk}/markers/${markerPk}/schedules/?${queryEncode(
          searchParams
        )}`,
      }),
      providesTags: ["schedules"],
    }),
    listPlaceSchedules: builder.query<
      Pagination<MarkerSchedule>,
      {
        placePk: QueryParam;
        search: QueryParam;
      }
    >({
      query: ({ placePk, ...searchParams }) => ({
        url: `places/${placePk}/schedules/?${queryEncode(searchParams)}`,
      }),
      providesTags: ["schedules"],
    }),
    retrieveSchedule: builder.query<
      MarkerSchedule,
      Pick<MarkerSchedule, "pk"> & {
        placePk: QueryParam;
        markerPk: QueryParam;
      }
    >({
      query: ({ markerPk, placePk, pk }) => ({
        url: `places/${placePk}/markers/${markerPk}/schedules/${pk}/`,
      }),
      providesTags: ["schedules"],
    }),
    createSchedule: builder.mutation<
      MarkerSchedule,
      {
        startDatetime: QueryParam;
        endDatetime: QueryParam;
        price?: QueryParam;
        placePk: QueryParam;
        markerPk: QueryParam;
        captainEmail: QueryParam;
        registration: QueryParam;
      }
    >({
      query: ({ placePk, markerPk, ...body }) => ({
        url: `places/${placePk}/markers/${markerPk}/schedules/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["schedules", "markers"],
    }),
    updateSchedule: builder.mutation<
      MarkerSchedule,
      Partial<MarkerSchedule> & {
        placePk: QueryParam;
        markerPk: QueryParam;
        markerSchedulePk: QueryParam;
      }
    >({
      query: ({ placePk, markerPk, markerSchedulePk, ...body }) => ({
        url: `places/${placePk}/markers/${markerPk}/schedules/${markerSchedulePk}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["schedules", "markers"],
    }),
    destroySchedule: builder.mutation<
      MarkerSchedule,
      Pick<MarkerSchedule, "pk"> & {
        placePk: QueryParam;
        markerPk: QueryParam;
      }
    >({
      query: ({ placePk, markerPk, pk, ...body }) => ({
        url: `places/${placePk}/markers/${markerPk}/schedules/${pk}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["schedules", "markers"],
    }),
    listPlaceDocuments: builder.query<
      Pagination<PlaceDocument>,
      { placePk: QueryParam; placeItemPk: QueryParam }
    >({
      query: ({ placePk, placeItemPk, ...searchParams }) => ({
        url: `places/${placePk}/items/${placeItemPk}/documents/`,
      }),
      providesTags: ["documents"],
    }),
    createPlaceDocument: builder.mutation<
      PlaceDocument,
      { placePk: QueryParam; placeItemPk: QueryParam; form: FormData }
    >({
      query: ({ placePk, placeItemPk, ...body }) => ({
        url: `places/${placePk}/items/${placeItemPk}/documents/`,
        method: "POST",
        body: body.form,
      }),
      invalidatesTags: ["documents", "schedules"],
    }),
    updatePlaceDocument: builder.mutation<
      PlaceDocument,
      Pick<PlaceDocument, "name"> & {
        placePk: QueryParam;
        placeItemPk: QueryParam;
        placeDocumentPk: QueryParam;
      }
    >({
      query: ({ placePk, placeItemPk, placeDocumentPk, ...body }) => ({
        url: `places/${placePk}/items/${placeItemPk}/documents/${placeDocumentPk}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["documents", "schedules"],
    }),
    destroyPlaceDocument: builder.mutation<
      PlaceDocument,
      {
        placePk: QueryParam;
        placeItemPk: QueryParam;
        placeDocumentPk: QueryParam;
      }
    >({
      query: ({ placePk, placeItemPk, placeDocumentPk }) => ({
        url: `places/${placePk}/items/${placeItemPk}/documents/${placeDocumentPk}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["documents", "schedules"],
    }),
    listPlaceDiscounts: builder.query<
      PlaceDiscount[],
      { placePk: QueryParam; }
    >({
      query: ({ placePk }) => ({
        url: `places/${placePk}/discounts/`,
      }),
      providesTags: ["discounts"],
    }),
    createPlaceDiscount: builder.mutation<
      PlaceDiscount,
      Partial<PlaceDiscount> & { placePk: QueryParam; }
    >({
      query: ({ placePk, ...body }) => ({
        url: `places/${placePk}/discounts/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["discounts"],
    }),
    destroyPlaceDiscount: builder.mutation<
      PlaceDiscount,
      { placePk: QueryParam; placeDiscountPk: QueryParam; form: FormData }
    >({
      query: ({ placePk, placeDiscountPk, ...body }) => ({
        url: `places/${placePk}/discounts/${placeDiscountPk}/`,
        method: "POST",
        body: body.form,
      }),
      invalidatesTags: ["discounts"],
    }),
  }),
});
