import React from "react";
import { es, enUS } from "date-fns/locale";
import { format, formatDuration } from "date-fns";
import { useTranslation } from "react-i18next";

const ESCAPE_KEY = 27;

const useEscapeKey = (callback: () => void, deps: any[] = []) => {
  React.useEffect(() => {
    const onKeyPress = (event: { keyCode: number }) => {
      event.keyCode === ESCAPE_KEY && callback();
    };

    window.document.addEventListener("keydown", onKeyPress);
    return () => {
      window.document.removeEventListener("keydown", onKeyPress);
    };
  }, deps);
};

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  React.useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => {
      window.removeEventListener("scroll", updatePosition);
    };
  }, []);

  return scrollPosition;
};

const locales: any = {
  en: enUS,
  es,
};
const useFormatDate = () => {
  const { i18n } = useTranslation("translation");
  const l = i18n.language;

  return (d: Date, formatStr = "d, MMM HH:mm") =>
    format(d, formatStr, { locale: locales[l] ?? locales.es });
};

const useFormatDuration = () => {
  const { i18n } = useTranslation("translation");
  const l = i18n.language;

  return (duration: Duration) =>
    formatDuration(duration, {
      locale: locales[l] ?? locales.es,
      format: ["years", "months", "weeks", "days", "hours"],
    });
};

export { useEscapeKey, useScrollPosition, useFormatDate, useFormatDuration };
