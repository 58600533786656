import React from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { selectToken, setToken } from "../store/members";
import { useTranslation } from "react-i18next";
import { mainApi } from "../services/api/main";
import { useScrollPosition } from "../utils/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Filter, IconBack, IconClose, IconMenu } from "../components";
import Logo from "../components/logo";
import { selectMarkerFilters } from "../store/ui";
import { useModal } from "../utils/modal";

const AccountLayout = ({ children }: { children: React.ReactElement }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation("translation");
  const token = useAppSelector(selectToken);
  const { placePk } = useParams();
  const { data } = mainApi.useRetrievePlaceQuery(
    { pk: Number(placePk) },
    { skip: !placePk }
  );
  const scrollY = useScrollPosition();
  const [showMenu, setShowMenu] = useModal();
  const dispatch = useAppDispatch();

  const markerFilters = useAppSelector(selectMarkerFilters);

  React.useEffect(() => {
    if (!token && pathname.startsWith("/ports")) {
      navigate("/login");
    }
  }, [token, pathname, navigate]);

  if (!token) {
    return null;
  }

  const toggleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const hideMenu = () => {
    setShowMenu(false);
  };

  const logout = () => {
    hideMenu();
    dispatch(setToken(""));
    navigate("/");
  };

  const goBack = () => {
    navigate(-1);
  };

  const goToPath = (path: string) => {
    setShowMenu(false);
    setTimeout(() => {
      navigate(path);
    }, 10);
  };

  const categories = [
    {
      name: t("account.markers"),
      path: `/ports/places/${placePk}/markers`,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="currentColor"
          viewBox="0 0 48 48"
        >
          <path d="M24 23.8q1.55 0 2.675-1.125Q27.8 21.55 27.8 20q0-1.55-1.125-2.675Q25.55 16.2 24 16.2q-1.55 0-2.675 1.125Q20.2 18.45 20.2 20q0 1.55 1.125 2.675Q22.45 23.8 24 23.8Zm0 15.1q6.2-5.7 9.175-10.35 2.975-4.65 2.975-8.15 0-5.5-3.525-9.025Q29.1 7.85 24 7.85t-8.625 3.525Q11.85 14.9 11.85 20.4q0 3.5 3.05 8.125T24 38.9Zm0 6.2q-8.45-7.2-12.65-13.35-4.2-6.15-4.2-11.35 0-7.9 5.075-12.575Q17.3 3.15 24 3.15t11.775 4.675Q40.85 12.5 40.85 20.4q0 5.2-4.2 11.35Q32.45 37.9 24 45.1Zm0-24.7Z" />
        </svg>
      ),
    },
    {
      name: t("bookings.bookings"),
      path: `/ports/places/${placePk}/bookings`,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="currentColor"
          viewBox="0 0 48 48"
        >
          <path d="M24 27.5ZM6.4 37.65 3.45 27.2q-.4-1.4 0-2.525t1.4-1.475l1.9-.65V11q0-1.7 1.25-2.9t3-1.25h6.25v-6H30.8v6H37q1.75 0 3.025 1.225T41.3 11v11.6l1.95.6q.7.25 1.225 1.275.525 1.025.075 2.675l-2.9 10.5q-3-.2-5.5-1.25t-4.1-2.6q-1.6 1.8-3.625 2.825Q26.4 37.65 24 37.65t-4.425-1.025Q17.55 35.6 15.95 33.8q-1.6 1.55-4.1 2.6-2.5 1.05-5.45 1.25ZM4 47.15v-4.7h4q2.1 0 4.15-.65 2.05-.65 3.85-1.85 1.8 1.2 3.85 1.8 2.05.6 4.15.6 2.1 0 4.15-.6 2.05-.6 3.85-1.8 1.8 1.2 3.85 1.85 2.05.65 4.15.65h4v4.7h-4q-2.05 0-4.05-.5T32 45.1q-1.95 1.05-4 1.55t-4 .5q-1.95 0-4-.5t-4-1.55q-1.95 1.05-3.95 1.55t-4.05.5Zm7.45-26 12.55-4 12.55 4.05v-9.65h-25.1ZM24 34.65q2.65 0 4.95-2.05 2.3-2.05 3.05-3.45.4.8 2.1 2.3 1.7 1.5 3.6 2.55l2.4-8.5L24 20.3 7.85 25.5l2.4 8.5q1.65-.85 3.55-2.5t2.2-2.35q.75 1.4 2.925 3.45Q21.1 34.65 24 34.65Z" />
        </svg>
      ),
    },
    {
      name: t("settings.settings"),
      path: `/ports/places/${placePk}/settings`,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="currentColor"
          viewBox="0 96 960 960"
        >
          <path d="m388 976-20-126q-19-7-40-19t-37-25l-118 54-93-164 108-79q-2-9-2.5-20.5T185 576q0-9 .5-20.5T188 535L80 456l93-164 118 54q16-13 37-25t40-18l20-127h184l20 126q19 7 40.5 18.5T669 346l118-54 93 164-108 77q2 10 2.5 21.5t.5 21.5q0 10-.5 21t-2.5 21l108 78-93 164-118-54q-16 13-36.5 25.5T592 850l-20 126H388Zm92-270q54 0 92-38t38-92q0-54-38-92t-92-38q-54 0-92 38t-38 92q0 54 38 92t92 38Zm0-60q-29 0-49.5-20.5T410 576q0-29 20.5-49.5T480 506q29 0 49.5 20.5T550 576q0 29-20.5 49.5T480 646Zm0-70Zm-44 340h88l14-112q33-8 62.5-25t53.5-41l106 46 40-72-94-69q4-17 6.5-33.5T715 576q0-17-2-33.5t-7-33.5l94-69-40-72-106 46q-23-26-52-43.5T538 348l-14-112h-88l-14 112q-34 7-63.5 24T306 414l-106-46-40 72 94 69q-4 17-6.5 33.5T245 576q0 17 2.5 33.5T254 643l-94 69 40 72 106-46q24 24 53.5 41t62.5 25l14 112Z"/>
        </svg>
      ),
    },
  ];

  return (
    <div className="">
      <nav
        className={`z-10 fixed bg-white w-full border-b h-16 flex flex-row items-center border-white md:border-gray-100 px-2 md:px-4 ${
          scrollY > 0 ? "shadow" : ""
        }`}
      >
        <div className="w-full flex flex-row items-center justify-between h-14">
          <div>
            <Logo onClick={hideMenu} />
            {!markerFilters.show && (
              <div className="block md:hidden cursor-pointer" onClick={goBack}>
                <IconBack className="h-8 w-8 mr-2" />
              </div>
            )}
          </div>
          {markerFilters.show && <Filter />}
          <div className="font-medium">
            <div
              className="cursor-pointer hidden text-gray-900 lg:inline-flex items-center mr-8"
              onClick={() => {
                goToPath("/ports?redirect=no");
              }}
            >
              <div>{t("account.places")}</div>
            </div>
            <div
              className="cursor-pointer hidden text-gray-900 lg:inline-flex items-center"
              onClick={logout}
            >
              <div>{t("account.logout")}</div>
            </div>
          </div>

          <div
            className="flex lg:hidden text-gray-800 h-6 w-6 cursor-pointer mr-2 ml-4"
            onClick={toggleShowMenu}
          >
            <IconMenu />
          </div>
        </div>
      </nav>
      <main className="w-full">
        <div className="flex flex-wrap relative">
          <aside
            className={`${
              showMenu ? "" : "hidden"
            } z-30 fixed lg:z-0 lg:relative inset-0 lg:mt-0 bg-white lg:bg-transparent ${
              placePk ? "lg:block w-full lg:w-1/5" : ""
            }`}
          >
            <div className="lg:sticky overflow-y-auto h-full lg:h-auto">
              <div
                onClick={hideMenu}
                className="flex flex-row justify-between items-center p-4"
              >
                <Logo onClick={hideMenu} className="inline-flex md:hidden" />
                <IconClose className="h-6 w-6 cursor-pointer" />
              </div>
              <ul className="p-4 lg:py-8 lg:pr-4">
                {placePk && (
                  <ul className="mb-8">
                    <p className="mb-2 text-gray-500 uppercase tracking-wider font-bold text-sm lg:text-xs w-full flex flex-row">
                      <span className="truncate flex-1 mr">
                        {data?.name ?? t("account.place")}
                      </span>
                    </p>
                    {categories.map((i) => (
                      <li className="text-gray-700" key={i.name}>
                        <div
                          className={`cursor-pointer border rounded py-2 mt-2 px-4 hover:text-blue-500 flex items-center justify-start ${
                            pathname.startsWith(i.path)
                              ? "text-blue-500 bg-blue-50 border-blue-500"
                              : "text-gray-500"
                          }`}
                          onClick={() => {
                            goToPath(i.path);
                          }}
                        >
                          {i.icon}
                          <div className="ml-4">{i.name}</div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
                <li className="lg:hidden space-x-2">
                  <p className="mb-2 text-gray-500 uppercase tracking-wider font-bold text-sm lg:text-xs">
                    {t("account.more")}
                  </p>
                  <div className="flex items-center space-x-8">
                    <div
                      onClick={() => {
                        goToPath("/ports?redirect=no");
                      }}
                      className="cursor-pointer font-semibold leading-none text-gray-700 dark:text-gray-300 hover:text-primary-500 dark-hover:text-primary-500 text-base"
                    >
                      {t("account.places")}
                    </div>
                    <div
                      onClick={logout}
                      className="cursor-pointer font-semibold leading-none text-gray-700 dark:text-gray-300 hover:text-primary-500 dark-hover:text-primary-500 text-base"
                    >
                      {t("account.logout")}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </aside>
          {children}
        </div>
      </main>
    </div>
  );
};

export default AccountLayout;
