import React from "react";
import { Marker } from "react-leaflet";
import { Marker as M } from "../../types/services/main";
import {
  iconAvailableLocation,
  iconSelectedLocation,
  iconUnavailableLocation,
} from "./locationIcons";

const DockMarker = ({
  onPkChange,
  item,
  isSelected,
}: {
  isSelected: boolean;
  onPkChange: (index: number) => void;
  item: M;
}) => {
  return (
    <Marker
      eventHandlers={{
        click: () => {
          onPkChange(item.pk);
        },
      }}
      position={{ lat: item.position.latitude, lng: item.position.longitude }}
      icon={
        isSelected
          ? iconSelectedLocation
          : item.available
          ? iconAvailableLocation
          : iconUnavailableLocation
      }
    ></Marker>
  );
};

export default DockMarker;
