import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AppHeader, AppPage, DefaultButton, DefaultLabel, DefaultPriceInputTable, IconLoad, Modal } from "../../components";
import { mainApi } from "../../services/api/main";
import { Variant } from "../../types/components";
import { useModal } from "../../utils/modal";
import { SubmitHandler, useForm } from "react-hook-form";
import DefaultInput from "../../components/defaultInput";
import toast from "react-hot-toast";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import errorParser from "../../utils/errorParser";
import currency from "currency.js";
import { getDay, getMonth, parseISO } from "date-fns";
import { filterEmpty } from "../../utils/objects";

interface CreateDiscountFormValues {
  name: string;
  percentage: string;
  days: number;
  dateStart: string;
  dateEnd: string;
}

const IndexSettings = () => {
  const { t } = useTranslation("translation");
  const { placePk } = useParams();
  const {data: discountsData, isLoading: isLoadingDiscounts} = mainApi.useListPlaceDiscountsQuery({placePk})
  const [prices, setPrices] = React.useState([{length: "0", monohull: "0", catamaran: "0"}])
  const [showCreateDiscount, setShowCreateDiscount] = useModal();
  const [updatePlace, {isLoading}] = mainApi.useUpdatePlaceMutation(undefined)
  const {data: place, isLoading: isLoadingPlace} = mainApi.useRetrievePlaceQuery({pk: placePk as any as number})
  const [createDiscount, {isLoading: isLoadingDiscountCreate}] = mainApi.useCreatePlaceDiscountMutation(undefined)

  const discounts = discountsData ?? []

  React.useEffect(() => {
    if (place?.prices) {
      setPrices(place?.prices.map(i => ({length: i.length, monohull: i.monohull / 100, catamaran: i.catamaran / 100})) as any)
    }
  }, [place?.prices])

  const {
    register: registerDiscount,
    handleSubmit: handleSubmitDiscount,
    reset: resetDiscount,
    formState: { isValid: isValidDiscount },
    
  } = useForm<CreateDiscountFormValues>({ mode: "onChange" });

  const onSubmitCreateDiscount: SubmitHandler<CreateDiscountFormValues> = async (data) => {
    try {
      const dateStart = parseISO(data.dateStart)
      const dateEnd = parseISO(data.dateEnd)
      await createDiscount(filterEmpty({placePk, days: data.days, name: data.name, percentage: currency(data.percentage).intValue, dayStart: getDay(dateStart), dayEnd: getDay(dateEnd), monthEnd: getMonth(dateEnd), monthStart: getMonth(dateStart)}) as any).unwrap();
      setShowCreateDiscount(false);
      resetDiscount();
    } catch (e) {
      errorParser(e as FetchBaseQueryError).forEach((i) => toast.error(i));
    }
  }
  
  return (
    <div className="flex flex-wrap-reverse flex-1 mt-2">
      <AppPage className="lg:w-full">
        <AppHeader title={t("settings.settings")} />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="border border-gray-200 rounded-lg p-4 w-full">
            <div className="text-md font-medium">{t('settings.prices')}</div>
            {isLoadingPlace ? (
              <div>
                <IconLoad className="my-10 mx-auto w-full" />
              </div>
            ) : (
              <div>
                <DefaultPriceInputTable prices={prices} setPrices={setPrices} />
                <DefaultButton
                  variant={Variant.primary}
                  value={t("settings.savePrices")}
                  className="w-full mt-2"
                  onClick={() => {updatePlace({prices: prices.map((i) => ({
                    length: parseInt(i.length),
                    monohull: currency(i.monohull).multiply(100).value,
                    catamaran: currency(i.catamaran).multiply(100).value,
                  })) as any, placePk})}}
                  loading={isLoading}
                />
              </div>
            )}
          </div>
          <div className="border border-gray-200 rounded-lg p-4 w-full flex flex-col justify-between">
            <div className="text-md font-medium">{t('settings.discounts')}</div>
            <div>
              {isLoadingDiscounts ? (
                <div>
                  <IconLoad className="my-10 mx-auto w-full" />
                </div>
              ) : (
                <div>
                  {discounts.length === 0 ? (
                    <div className="text-center">{t('settings.noDiscounts')}</div>
                  ) : (
                    <div>
                      
                    </div>
                  )}
                  <div>
                    <DefaultButton className="w-full" value={t('settings.addNewDiscount')} variant={Variant.outline} loading={false} onClick={() => {setShowCreateDiscount(true)}} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </AppPage>
      <Modal
        visible={showCreateDiscount}
        onRequestClose={() => {
          setShowCreateDiscount(false);
          resetDiscount();
        }}
        className="w-full md:w-3/5 lg:w-2/5"
        title={t("account.addEntry")}
      >
        <form onSubmit={handleSubmitDiscount(onSubmitCreateDiscount)}>
        <DefaultLabel htmlFor="name" className="mt-2 mb-2">
            {t("settings.discountName")}
          </DefaultLabel>
          <DefaultInput
            placeholder={t("account.name")}
            id="name"
            type="text"
            {...registerDiscount("name", { required: true })}
          />
          <DefaultLabel htmlFor="percentage" className="mt-2 mb-2">
            {t("settings.discountPercentage")}
          </DefaultLabel>
          <DefaultInput
            placeholder={t("settings.percentage")}
            id="percentage"
            type="number"
            {...registerDiscount("percentage", { required: true })}
          />
          <DefaultLabel htmlFor="days" className="mt-2 mb-2">
            {t("settings.discountDays")}
          </DefaultLabel>
          <DefaultInput
            placeholder={t("settings.days")}
            id="days"
            type="number"
            {...registerDiscount("days", { required: false })}
          />
          <DefaultLabel htmlFor="dateStart" className="mt-2 mb-2">
            {t("settings.discountDateStart")}
          </DefaultLabel>
          <DefaultInput
            placeholder={t("settings.discountDateStart")}
            id="dateStart"
            type="date"
            {...registerDiscount("dateStart", { required: false })}
          />
          <DefaultLabel htmlFor="dateEnd" className="mt-2 mb-2">
            {t("settings.discountDateEnd")}
          </DefaultLabel>
          <DefaultInput
            placeholder={t("settings.dateEnd")}
            id="dateEnd"
            type="date"
            {...registerDiscount("dateEnd", { required: false })}
          />
          <div className="flex flex-row justify-end items-center mt-14">
            <DefaultButton
              value={t("settings.createDiscount")}
              variant={Variant.primary}
              disabled={!isValidDiscount}
              className="ml-4"
              type="submit"
              loading={isLoadingDiscountCreate}
            />
          </div>
          </form>
      </Modal>
    </div>
  );
};

export default IndexSettings;
