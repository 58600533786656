import { type ReactElement } from "react";

const AppPage = ({
  children,
  className,
  classNameChildren,
}: {
  children: ReactElement | ReactElement[];
  className?: string;
  classNameChildren?: string;
}) => (
  <div
    className={`w-full px-2 lg:w-4/5 py-4 lg:pb-4 pt-16 lg:border-l lg:border-r min-h-screen border-gray-100 ${className}`}
  >
    <article className={`prose max-w-none lg:px-8 ${classNameChildren}`}>
      {children}
    </article>
  </div>
);

export default AppPage;
