import React from "react";
import {
  AppHeader,
  AppPage,
  AppToc,
  DefaultLabel,
  DefaultInput,
  CardSchedule,
  IconLoad,
} from "../../components";
import { useTranslation } from "react-i18next";
import { mainApi } from "../../services/api/main";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { debounce } from "lodash";

const IndexBookings = () => {
  const { t } = useTranslation("translation");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { placePk } = useParams();
  const searchTerm = searchParams.get("search") ?? "";
  const { data, isFetching } = mainApi.useListPlaceSchedulesQuery({
    search: searchTerm,
    placePk,
  });
  const schedules = data?.results ?? [];
  const [search, setSearch] = React.useState(searchTerm);
  const navigateDebounced: any = React.useCallback(
    debounce(navigate, 200),
    []
  );

  React.useEffect(() => {
    navigateDebounced({ search: `?search=${search}` }, { replace: true });
  }, [search]);

  const inputs = (
    <>
      <DefaultLabel htmlFor="registration" className="mb-1 mt-2">
        {t("account.registrationOrName")}
      </DefaultLabel>
      <DefaultInput
        id="registration"
        type="text"
        placeholder={t("account.enterSearchTerm")}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
    </>
  );

  return (
    <div className="flex flex-wrap-reverse flex-1 mt-2">
      <AppPage className="lg:w-4/6">
        <AppHeader title={t("bookings.bookings")} />
        <AppToc title={t("account.filters")} className="block lg:hidden mb-2">
          {inputs}
        </AppToc>
        <div>
          <div className="w-full flex flex-row justify-center items-center">
            {isFetching && (
              <div className="w-full flex justify-center mt-6">
                <IconLoad />
              </div>
            )}
          </div>
          {!isFetching &&
            schedules.map((i) => (
              <CardSchedule key={i.pk} placePk={placePk!} item={i} />
            ))}
          {!isFetching && schedules.length === 0 && (
            <div className="flex justify-center items-center mt-10 text-gray-500 text-center">
              {t("account.noBookings")}
            </div>
          )}
        </div>
      </AppPage>
      <AppToc title={t("account.filters")} className="hidden lg:block w-2/5">
        {inputs}
      </AppToc>
    </div>
  );
};

export default IndexBookings;
