import { useTranslation } from "react-i18next";
import { mainApi } from "../../../../../../../../services/api/main";
import { Size, Variant } from "../../../../../../../../types/components";
import React from "react";
import { useFormatDate } from "../../../../../../../../utils/hooks";
import { parseISO } from "date-fns";
import { type SubmitHandler, useForm } from "react-hook-form";
import errorParser from "../../../../../../../../utils/errorParser";
import { type FetchBaseQueryError } from "@reduxjs/toolkit/query";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  AppPage,
  DefaultButton,
  DefaultLabel,
  IconLoad,
  Modal,
  DefaultInput,
  AppHeader,
  DefaultSelect,
  IconDocument,
} from "../../../../../../../../components";
import { useModal } from "../../../../../../../../utils/modal";
import { PlaceDocumentCategory } from "../../../../../../../../types/services/main";

const placeDocumentMapping = {
  [PlaceDocumentCategory.liability]: "account.liabilityCert",
  [PlaceDocumentCategory.navigation]: "account.navCert",
  [PlaceDocumentCategory.ownership]: "account.ownerCert",
  [PlaceDocumentCategory.other]: "other",
};

interface CreateDocumentFormValues {
  documents: FileList;
  name: string;
  category: PlaceDocumentCategory;
}

const DetailSchedule = () => {
  const formatDate = useFormatDate();
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const { placePk, markerPk, schedulePk } = useParams();
  const { data: schedule, isLoading: isLoadingSchedule } =
    mainApi.useRetrieveScheduleQuery(
      { placePk, markerPk, pk: Number(schedulePk) },
      { skip: !markerPk || !placePk || !schedulePk }
    );
  const placeItemPk = schedule?.placeItemPk;
  const [createDocument, { isLoading: isLoadingCreateDocument }] =
    mainApi.useCreatePlaceDocumentMutation();
  const { data: documentsData, isFetching: isLoadingDocuments } =
    mainApi.useListPlaceDocumentsQuery(
      { placePk, placeItemPk: Number(placeItemPk) },
      { skip: !markerPk || !placeItemPk }
    );
  const documents = documentsData?.results ?? [];

  const [destroySchedule, { isLoading: isLoadingDestroySchedule }] =
    mainApi.useDestroyScheduleMutation();

  const [showCreate, setShowCreate] = useModal();
  const [showDelete, setShowDelete] = useModal();

  const toggleShowCreate = () => {
    setShowCreate(!showCreate);
  };

  const toggleShowDelete = () => {
    setShowDelete(!showDelete);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid: isValidSchedule },
    watch,
    setValue,
  } = useForm<CreateDocumentFormValues>({
    defaultValues: {
      category: PlaceDocumentCategory.other,
    },
  });
  const documentCategory = watch("category") ?? "";

  const onSubmitDestroySchedule = async () => {
    try {
      await destroySchedule({
        placePk,
        markerPk,
        pk: schedule!.pk,
      }).unwrap();
      toggleShowDelete();
      navigate(-1);
    } catch (e) {
      errorParser(e as FetchBaseQueryError).forEach((i) => toast.error(i));
    }
  };

  const onSubmitCreateDocument: SubmitHandler<
    CreateDocumentFormValues
  > = async ({ name, documents, category }) => {
    try {
      const data = new FormData();
      data.append("name", name);
      data.append("file", documents.item(0)!);
      data.append("category", category);
      await createDocument({
        placePk,
        placeItemPk,
        form: data,
      }).unwrap();
      toggleShowCreate();
      reset();
    } catch (e) {
      errorParser(e as FetchBaseQueryError).forEach((i) => toast.error(i));
    }
  };

  if (isLoadingSchedule) {
    return (
      <AppPage>
        <div className="w-full flex justify-center">
          <IconLoad />
        </div>
      </AppPage>
    );
  }

  const documentsLeftToUpload = [
    PlaceDocumentCategory.liability,
    PlaceDocumentCategory.navigation,
    PlaceDocumentCategory.ownership,
  ].filter((i) => !schedule?.documentCategoriesAvailable.includes(i));

  return (
    <div className="flex flex-wrap-reverse flex-1 mt-2">
      <AppPage className="lg:w-full lg:border-r-0">
        <AppHeader
          title={t("account.entryDetails")}
          description={`${formatDate(
            parseISO(schedule?.startDatetime ?? ""),
            "PPpp"
          )} - ${formatDate(parseISO(schedule?.endDatetime ?? ""), "PPpp")}`}
          onClickCreate={toggleShowCreate}
          buttonTitle={t("account.addDocumentation")}
          onClickDelete={toggleShowDelete}
        />

        <div>
          {isLoadingDocuments && (
            <div className="w-full flex justify-center mt-10">
              <IconLoad />
            </div>
          )}
          {!isLoadingDocuments && documents.length < 3 && (
            <div className="w-full flex justify-center bg-orange-100 text-orange-500 rounded p-4">
              {t("account.documentReminder")}
            </div>
          )}
        </div>

        <div className="grid gap-4 grid-cols-2 md:grid-cols-3 xl:grid-cols-4 w-full mt-4">
          {!isLoadingDocuments &&
            documentsLeftToUpload.map((i) => (
              <div
                key={i}
                className="flex flex-col justify-center items-center border border-gray-200 p-2 rounded-lg"
              >
                <IconDocument className="h-8 w-8 text-gray-800" />
                <div className="flex-1 my-2">
                  <div>{t(placeDocumentMapping[i])}</div>
                </div>
                <DefaultButton
                  value={t("add")}
                  variant={Variant.primary}
                  size={Size.sm}
                  className="w-full mt-2"
                  onClick={() => {
                    setValue("category", i);
                    setShowCreate(true);
                  }}
                />
              </div>
            ))}
          {documents.map((i) => (
            <div
              key={i.pk}
              className="flex flex-col justify-center items-center border border-gray-200 p-2 rounded-lg"
            >
              <IconDocument className="h-8 w-8 text-gray-800" />
              <div className="flex-1 my-2">
                <div>{i.name || t(placeDocumentMapping[i.category])}</div>
              </div>
              <DefaultButton
                value={t("download")}
                variant={Variant.outline}
                size={Size.sm}
                className="w-full mt-2"
                onClick={() => {
                  window.open(i.file as string);
                }}
              />
            </div>
          ))}
        </div>
      </AppPage>
      <Modal
        visible={showCreate}
        onRequestClose={() => {
          setShowCreate(false);
          reset();
        }}
        className="w-full md:w-3/5 lg:w-2/5"
        title={t("account.newDocument")}
      >
        <div className="text-md text-gray-500">
          {t("account.createDocumentMessage")}
        </div>
        <form onSubmit={handleSubmit(onSubmitCreateDocument)}>
          <DefaultLabel htmlFor="category" className="mb-1 mt-2">
            {t("account.documentType")}
          </DefaultLabel>
          <DefaultSelect
            options={[
              {
                name: t(placeDocumentMapping[PlaceDocumentCategory.other]),
                value: PlaceDocumentCategory.other,
              },
              {
                name: t(
                  placeDocumentMapping[PlaceDocumentCategory.navigation]
                ),
                value: PlaceDocumentCategory.navigation,
              },
              {
                name: t(placeDocumentMapping[PlaceDocumentCategory.ownership]),
                value: PlaceDocumentCategory.ownership,
              },
              {
                name: t(placeDocumentMapping[PlaceDocumentCategory.liability]),
                value: PlaceDocumentCategory.liability,
              },
            ]}
            id="category"
            {...register("category", { required: true })}
          />
          {documentCategory === PlaceDocumentCategory.other && (
            <>
              <DefaultLabel htmlFor="name" className="mb-1 mt-2">
                {t("account.documentName")}
              </DefaultLabel>
              <DefaultInput
                id="name"
                type="text"
                placeholder={t("account.enterName")}
                {...register("name", { required: true })}
              />
            </>
          )}
          <DefaultLabel htmlFor="documents" className="mb-1 mt-4">
            {t("account.document")}
          </DefaultLabel>
          <DefaultInput
            id="documents"
            type="file"
            accept="image/*, .pdf, .doc, .docx"
            {...register("documents", {})}
          />
          <div className="flex flex-row justify-end items-center mt-14">
            <DefaultButton
              value={t("account.addDocumentation")}
              variant={Variant.primary}
              disabled={!isValidSchedule}
              className="ml-4"
              type="submit"
              loading={isLoadingCreateDocument}
            />
          </div>
        </form>
      </Modal>
      <Modal
        visible={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
        }}
        className="w-full md:w-3/5 lg:w-2/5"
        title={t("account.removeSchedule")}
      >
        <div className="text-md text-gray-500">
          {t("account.removeScheduleMessage")}
        </div>
        <div className="flex flex-row justify-end items-center mt-14">
          <DefaultButton
            value={t("account.removeSchedule")}
            variant={Variant.outlineRed}
            className="ml-4"
            onClick={onSubmitDestroySchedule}
            loading={isLoadingDestroySchedule}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DetailSchedule;
