import React from "react";
import { useTranslation } from "react-i18next";
import { useSwiper, useSwiperSlide } from "swiper/react";
import { Size, Variant } from "../../types/components";
import DefaultButton from "../defaultButton";
import { Marker } from "../../types/services/main";

const SwiperItem = ({
  item,
  index,
  onNavigate,
  onEntry,
}: {
  item: Marker;
  index: number;
  onNavigate: () => void;
  onEntry: () => void;
}) => {
  const { t } = useTranslation();
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();

  return (
    <div
      className={`text-[10px] flex flex-col justify-start bg-white rounded-lg shadow-md p-2 cursor-pointer select-none border-2 border-white ${
        swiperSlide.isActive && "md:border-blue-500"
      }`}
      onClick={() => {
        swiper.slideTo(index);
      }}
    >
      <div className="flex flex-row justify-between items-center">
        <div>{t("account.name")}</div>
        <div className="bg-gray-100 text-gray-800 px-2 rounded-lg whitespace-nowrap text-ellipsis overflow-hidden ml-2">
          {item.name || "-"}
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mt-1">
        <div>{t("account.length")}</div>
        <div className="bg-gray-100 text-gray-800 px-2 rounded-lg">
          {item.length}
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mt-1">
        <div>{t("account.width")}</div>
        <div className="bg-gray-100 text-gray-800 px-2 rounded-lg">
          {item.width}
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mt-1">
        <div>{t("account.depth")}</div>
        <div className="bg-gray-100 text-gray-800 px-2 rounded-lg">
          {item.depth}
        </div>
      </div>
      <div />
      <DefaultButton
        variant={Variant.outline}
        value={t("account.seeMarker")}
        size={Size.md}
        className="mt-2"
        onClick={onNavigate}
      />
      <DefaultButton
        variant={Variant.primary}
        value={t("account.addEntry")}
        size={Size.md}
        className="mt-2"
        disabled={!item.available}
        onClick={onEntry}
        id="props-basic"
      />
    </div>
  );
};

export default SwiperItem;
