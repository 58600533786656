import { LatLngLiteral, Map } from "leaflet";
import React from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";
import DefaultButton from "../defaultButton";
import { Variant } from "../../types/components";
import { Marker as MarkerType } from "../../types/services/main";
import SwiperList from "./swiperList";
import { iconAddLocation } from "./locationIcons";
import DockMarker from "./dockMarker";

const LocationMarker = ({
  onSelectedPosition,
  selectedPosition,
}: {
  selectedPosition: LatLngLiteral;
  onSelectedPosition: (position: LatLngLiteral) => void;
}) => {
  const [position, setPosition] =
    React.useState<LatLngLiteral>(selectedPosition);
  const map = useMapEvents({
    click(e) {
      onSelectedPosition({ lat: e.latlng.lat, lng: e.latlng.lng });
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  if (position) {
    return <Marker position={position} icon={iconAddLocation} />;
  }

  return null;
};

const FullMap = ({
  className,
  onCreateMarkerClick,
  markers,
  setSelectedPosition,
  selectedPosition,
  onAddEntry,
  onNavigateMarker,
  showExtraSelect,
  onExtraMarkerClick,
}: {
  setSelectedPosition: (position: LatLngLiteral) => void;
  selectedPosition: LatLngLiteral;
  markers: MarkerType[];
  className?: string;
  onCreateMarkerClick: () => void;
  onAddEntry: (pk: number) => void;
  onNavigateMarker: (pk: number) => void;
  showExtraSelect?: boolean;
  onExtraMarkerClick?: (pk: number) => void;
}) => {
  const { t } = useTranslation();
  const [enableCreate, setEnableCreate] = React.useState(false);
  const [currentPk, setCurrentPk] = React.useState(-1);
  const mapRef = React.useRef<Map>(null);
  const swiperRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (markers.length > 0) {
      setCurrentPk(markers[0].pk);
    }
  }, [markers]);

  React.useEffect(() => {
    if (currentPk !== -1) {
      const itemIndex = markers.findIndex((i) => i.pk === currentPk)!;
      if (itemIndex !== -1) {
        const item = markers[itemIndex];
        mapRef.current?.flyTo(
          { lat: item.position.latitude, lng: item.position.longitude },
          19
        );
        !enableCreate && swiperRef.current?.slideTo(itemIndex);
      }
    }
  }, [currentPk]);

  const setMap = () => {
    const resizeObserver = new ResizeObserver(() => {
      mapRef.current?.invalidateSize();
    });
    const container = document.getElementById("map-container");
    resizeObserver.observe(container!);
  };

  const toggleEnableCreate = () => {
    setEnableCreate(!enableCreate);
  };

  const onDockMarkerClick = (pk: number) => {
    if (showExtraSelect) {
      const selectedMarker = markers.find((i) => i.pk === pk);
      if (currentPk !== pk && selectedMarker?.available) {
        onExtraMarkerClick!(pk);
      } else {
        alert(t("account.markerAlreadySelected"));
      }
    } else {
      setCurrentPk(pk);
    }
  };

  return (
    <div
      className={`flex h-[calc(100vh-4rem)] w-full relative ${className}`}
      id="map-container"
    >
      <MapContainer
        whenReady={setMap}
        ref={mapRef}
        center={[42.2260838, -8.7604454]}
        zoom={16}
        zoomControl={false}
        style={{ width: "100%", height: "100%" }}
      >
        <TileLayer
          maxZoom={30}
          maxNativeZoom={19}
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {enableCreate && (
          <LocationMarker
            onSelectedPosition={setSelectedPosition}
            selectedPosition={selectedPosition}
          />
        )}
        {markers.map((i) => (
          <DockMarker
            key={i.pk}
            isSelected={currentPk === i.pk}
            item={i}
            onPkChange={onDockMarkerClick}
          />
        ))}
      </MapContainer>
      <div className="absolute bottom-5 w-full flex flex-row justify-center items-center z-1">
        {selectedPosition.lat !== 0 && enableCreate ? (
          <DefaultButton
            variant={Variant.primary}
            value={t("account.createMarker")}
            onClick={onCreateMarkerClick}
            className="drop-shadow-lg"
          />
        ) : (
          enableCreate && (
            <div className="bg-orange-600 border-2 border-white text-white font-medium text-center p-2 px-6 rounded-full drop-shadow-lg mx-4 text-xs">
              {t("account.touchMap")}
            </div>
          )
        )}
      </div>
      {!showExtraSelect && (
        <div className="absolute top-2 w-full flex flex-row justify-between items-center z-1">
          <div />
          <div
            className={`cursor-pointer p-2 rounded-full mr-2  drop-shadow-md ${
              enableCreate
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-900"
            }`}
            onClick={toggleEnableCreate}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 48 48"
              fill="currentColor"
            >
              <path d="M9.35 39.05h1.6L32.7 17.2l-1.6-1.6L9.35 37.45Zm30.5-23.95-6.6-6.6 1.05-1.1q1.4-1.4 3.325-1.425Q39.55 5.95 40.95 7.3l.9.9q1.15 1.1 1.025 2.55-.125 1.45-1.125 2.45Zm-2.05 2.05L12.35 42.6h-6.6V36l25.4-25.4Zm-5.85-.75-.85-.8 1.6 1.6Z" />
            </svg>
          </div>
        </div>
      )}
      <div className="absolute bottom-0 pb-5 pt-2 z-1 w-full">
        {!enableCreate && !showExtraSelect && (
          <SwiperList
            onSwiper={(s) => {
              swiperRef.current = s;
            }}
            markers={markers}
            onPkChange={(i) => {
              setCurrentPk(i);
            }}
            onPkNavigate={onNavigateMarker}
            onPkEntry={onAddEntry}
          />
        )}
      </div>
    </div>
  );
};

export default FullMap;
