import { useTranslation } from "react-i18next";
import { Variant } from "../../../../../types/components";
import React from "react";
import { mainApi } from "../../../../../services/api/main";
import { type SubmitHandler, useForm } from "react-hook-form";
import errorParser from "../../../../../utils/errorParser";
import { type FetchBaseQueryError } from "@reduxjs/toolkit/query";
import toast from "react-hot-toast";
import { filterEmpty } from "../../../../../utils/objects";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  AppPage,
  DefaultButton,
  DefaultLabel,
  DefaultInput,
  Modal,
  IconLoad,
  Map,
} from "../../../../../components";
import { LatLngLiteral } from "leaflet";
import { useDispatch } from "react-redux";
import { setMarkerFilters } from "../../../../../store/ui";
import { addHours, differenceInDays, formatISO, getDay, getMonth, parseISO } from "date-fns";
import { useFormatDate } from "../../../../../utils/hooks";
import { useModal } from "../../../../../utils/modal";
import currency from "currency.js";

interface CreateMarkerFormValues {
  name: string;
  length: number;
  width: number;
  depth: number;
}

interface CreateScheduleFormValues {
  captainEmail: string;
  registration: string;
  price: number;
  width: number;
  depth: number;
  length: number;
  extraMarkerPk: number;
  vehicleName: string;
}

const Index = () => {
  const formatDate = useFormatDate();
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { placePk } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedMarkerPosition, setSelectedMarkerPosition] =
    React.useState<LatLngLiteral>({ lat: 0, lng: 0 });
  const dispatch = useDispatch();
  const [showExtraSelect, setShowExtraSelect] = React.useState(false);
  const [lineItems, setLineItems] = React.useState<Array<{name: string; value: number}>>([])

  React.useEffect(() => {
    dispatch(setMarkerFilters({ show: true }));
    return () => {
      dispatch(setMarkerFilters({ show: false }));
    };
  });

  const defaultFilters = React.useMemo(() => {
    const nowDate = new Date();
    nowDate.setSeconds(0);
    nowDate.setMilliseconds(0);
    return {
      defaultStartDatetime: formatISO(nowDate),
      defaultEndDatetime: formatISO(addHours(nowDate, 24)),
    };
  }, []);

  const searchData = {
    placePk,
    length__gte: searchParams.get("length__gte") ?? "",
    width__gte: searchParams.get("width__gte") ?? "",
    depth__gte: searchParams.get("depth__gte") ?? "",
    start_datetime:
      searchParams.get("start_datetime")! ||
      defaultFilters.defaultStartDatetime,
    end_datetime:
      searchParams.get("end_datetime")! || defaultFilters.defaultEndDatetime,
  };

  const { data, isFetching } = mainApi.useListMarkersQuery(searchData, {
    skip: !placePk,
  });
  const markers = data ?? [];
  const { data: places } = mainApi.useListPlacesQuery(undefined);
  const place = places?.results.find((i) => i.pk === parseInt(placePk!));
  const { data: discounts } = mainApi.useListPlaceDiscountsQuery({ placePk });

  const [createMarker, { isLoading }] = mainApi.useCreateMarkerMutation();
  const [createSchedule, { isLoading: isCreatingSchedule }] =
    mainApi.useCreateScheduleMutation();

  const [markerPk, setMarkerPk] = React.useState(-1);
  const [showCreateSchedule, setShowCreateSchedule] = useModal();
  const [showCreate, setShowCreate] = useModal();
  const selectedMarker = markers.find((i) => i.pk === markerPk);

  const dateStart = parseISO(searchData.start_datetime)
  const dateEnd = parseISO(searchData.end_datetime)
  const daysDifference =
    differenceInDays(
      dateEnd,
      dateStart
    ) || 1;
  const selectedLength = selectedMarker?.length ?? 1;

  const toggleShowCreate = () => {
    setShowCreate(!showCreate);
  };

  const {
    register: registerMarker,
    handleSubmit: handleSubmitMarker,
    reset,
    formState: { isValid: isValidMarker },
  } = useForm<CreateMarkerFormValues>();

  const {
    register: registerSchedule,
    handleSubmit: handleSubmitSchedule,
    reset: resetSchedule,
    formState: { isValid: isValidSchedule, dirtyFields },
    setValue,
    getValues,
    watch,
  } = useForm<CreateScheduleFormValues>({ mode: "onChange" });
  const extraMarker = watch("extraMarkerPk");
  const isPriceDirty = !!dirtyFields.price;

  const onSubmitSchedule: SubmitHandler<CreateScheduleFormValues> = async (
    data
  ) => {
    try {
      await createSchedule({
        placePk,
        markerPk,
        ...data,
        price: data.price * 100,
        startDatetime: searchData.start_datetime,
        endDatetime: searchData.end_datetime,
      }).unwrap();
      setShowCreateSchedule(false);
      resetSchedule();
      setTimeout(() => {
        navigateToMarker(markerPk);
      }, 10);
    } catch (e) {
      errorParser(e as FetchBaseQueryError).forEach((i) => toast.error(i));
    }
  };

  const onSubmitCreateMarker: SubmitHandler<CreateMarkerFormValues> = async (
    data
  ) => {
    try {
      await createMarker({
        placePk,
        ...filterEmpty({
          ...data,
          position: {
            latitude: selectedMarkerPosition.lat,
            longitude: selectedMarkerPosition.lng,
          },
        }),
      }).unwrap();
      toggleShowCreate();
      reset();
      setSelectedMarkerPosition({ lat: 0, lng: 0 });
    } catch (e) {
      errorParser(e as FetchBaseQueryError).forEach((i) => toast.error(i));
    }
  };

  const navigateToMarker = (pk: number) => {
    navigate({
      pathname: `${pathname}/${pk}/schedules`,
      search: `?${createSearchParams({
        date: searchData.start_datetime,
      })}`,
    });
  };

  React.useEffect(() => {
    if (isPriceDirty) {
      setLineItems([...lineItems, {name: 'Manual', value: getValues("price")}])
    }
  }, [isPriceDirty])

  React.useEffect(() => {
    const prices =
      place?.prices
        .filter((i) => i.length >= selectedLength)
        .sort((a, b) => a.length - b.length) ?? [];
    if (prices.length === 0) {
      setValue("price", NaN);
      setLineItems([])
      return;
    }
    const items = []

    const price = prices[0];
    const isCatamaran = getValues("extraMarkerPk");
    const boatPrice = isCatamaran
      ? price.catamaran
      : price.monohull;

    const total = (boatPrice ?? -1) * daysDifference;
    items.push({name: t('account.boatPriceDays', {boatPrice: currency(boatPrice).divide(100).value, boatType: t(isCatamaran ? 'account.catamaran' : 'account.monohull'), days: daysDifference}), value: currency(total).divide(100).value})

    const dayStart = getDay(dateStart)
    const monthStart = getMonth(dateStart)
    const dayEnd = getDay(dateEnd)
    const monthEnd = getMonth(dateEnd)
    const filteredDiscounts = discounts?.filter(i => daysDifference >= i.days || i.dayStart < dayStart && i.monthStart < monthStart && i.dayEnd > dayEnd && i.monthEnd > monthEnd).sort((a, b) => b.percentage-a.percentage) ?? []
    
    let discount = 0
    if (filteredDiscounts.length > 0) {
      const d = filteredDiscounts[0]
      discount = -((d.percentage * total)) / 10000
      items.push({value: discount / 100, name: t('account.appliedDiscount', {name: `${d.name} - ${currency(d.percentage).divide(100).value}%`})})
    }
    const totalWithDiscount = total + discount

    const tax = (totalWithDiscount * (place?.taxRate ?? 0)) / 1000000;
    items.push({name: t('account.taxRateLine', {percentage: currency(place!.taxRate).divide(100).value}), value: currency(tax).divide(100).value})
    
    const totalWithTaxAndDiscount = tax + totalWithDiscount;
    setValue("price", currency(totalWithTaxAndDiscount).divide(100).value);
    setLineItems(items)
  }, [selectedLength, daysDifference, place?.prices, place?.taxRate, extraMarker, showCreateSchedule]);

  React.useEffect(() => {
    setValue("length", selectedLength);
  }, [selectedLength, showCreateSchedule]);

  const addEntry = (pk: number) => {
    setMarkerPk(pk);
    setShowCreateSchedule(true);
  };

  const onExtraMarkerClick = (pk: number) => {
    setValue("extraMarkerPk", pk);
    setShowCreateSchedule(true);
    setShowExtraSelect(false);
  };

  return (
    <div className="flex flex-wrap-reverse flex-1">
      <AppPage
        className="lg:w-full px-0 py-0 lg:pb-0"
        classNameChildren="lg:px-0"
      >
        <div className="relative">
          <div className="absolute top-0 w-full flex flex-row justify-center items-center">
            {isFetching && (
              <div className="bg-white rounded-b-lg p-4 drop-shadow-lg z-10">
                <IconLoad />
              </div>
            )}
          </div>
          <Map
            onCreateMarkerClick={toggleShowCreate}
            markers={markers}
            selectedPosition={selectedMarkerPosition}
            setSelectedPosition={setSelectedMarkerPosition}
            onAddEntry={addEntry}
            onNavigateMarker={navigateToMarker}
            onExtraMarkerClick={onExtraMarkerClick}
            showExtraSelect={showExtraSelect}
          />
        </div>
      </AppPage>
      <Modal
        visible={!!showCreate}
        onRequestClose={() => {
          setShowCreate(false);
          reset();
        }}
        className="w-full md:w-3/5 lg:w-2/5"
        title={t("account.newMarker")}
      >
        <div className="text-md text-gray-500">
          {t("account.createMarkerMessage")}
        </div>
        <form onSubmit={handleSubmitMarker(onSubmitCreateMarker)}>
          <DefaultLabel htmlFor="name" className="mt-2 mb-2">
            {t("account.spaceName")}
          </DefaultLabel>
          <DefaultInput
            placeholder={t("account.enterName")}
            id="name"
            type="text"
            {...registerMarker("name", { required: true })}
          />
          <DefaultLabel htmlFor="length" className="mt-2 mb-2">
            {t("account.length")} ({t("account.metres")})
          </DefaultLabel>
          <DefaultInput
            placeholder={t("account.length")}
            id="length"
            type="number"
            {...registerMarker("length", { required: true })}
          />
          <DefaultLabel htmlFor="width" className="mt-2 mb-2">
            {t("account.width")} ({t("account.metres")})
          </DefaultLabel>
          <DefaultInput
            placeholder={t("account.width")}
            id="width"
            type="number"
            {...registerMarker("width", { required: true })}
          />
          <DefaultLabel htmlFor="depth" className="mt-2 mb-2">
            {t("account.depth")} ({t("account.metres")})
          </DefaultLabel>
          <DefaultInput
            placeholder={t("account.depth")}
            id="depth"
            type="number"
            {...registerMarker("depth", { required: true })}
          />
          {/* <DefaultLabel htmlFor="country" className="mt-6 mb-2"> */}
          {/*  {t('account.country')} */}
          {/* </DefaultLabel> */}
          {/* <DefaultInput */}
          {/*  placeholder={t('account.enterCountry')} */}
          {/*  id="country" */}
          {/*  type="text" */}
          {/*  {...register('country', { required: true })} */}
          {/* /> */}
          {/* <DefaultLabel htmlFor="city" className="mt-6 mb-2"> */}
          {/*  {t('account.city')} */}
          {/* </DefaultLabel> */}
          {/* <DefaultInput */}
          {/*  placeholder={t('account.enterCity')} */}
          {/*  id="name" */}
          {/*  type="text" */}
          {/*  {...register('city', { required: true })} */}
          {/* /> */}
          <div className="flex flex-row justify-end items-center mt-14">
            <DefaultButton
              value={t("account.createMarker")}
              variant={Variant.primary}
              disabled={!isValidMarker}
              className="ml-4"
              type="submit"
              loading={isLoading}
            />
          </div>
        </form>
      </Modal>

      <Modal
        visible={showCreateSchedule}
        onRequestClose={() => {
          setShowCreateSchedule(false);
          resetSchedule();
        }}
        className="w-full md:w-3/5 lg:w-2/5"
        title={t("account.addEntry")}
      >
        <form onSubmit={handleSubmitSchedule(onSubmitSchedule)}>
          {/* <DefaultLabel htmlFor="width" className="mb-1 mt-2">
                    {t("account.width")} ({t("account.metres")})
                  </DefaultLabel>
                  <DefaultInput
                    placeholder={t("account.width")}
                    id="width"
                    type="number"
                    onFocus={e => {e.target.select()}}
                    {...registerSchedule("width", { required: false })}
                  />
                  <DefaultLabel htmlFor="depth" className="mb-1 mt-2">
                    {t("account.depth")} ({t("account.metres")})
                  </DefaultLabel>
                  <DefaultInput
                    placeholder={t("account.depth")}
                    id="depth"
                    type="number"
                    onFocus={e => {e.target.select()}}
                    {...registerSchedule("depth", { required: false })}
                  /> */}
          <DefaultLabel htmlFor="registration" className="mt-2 mb-2">
            {t("account.registration")}
          </DefaultLabel>
          <DefaultInput
            placeholder={t("account.enterRegistration")}
            id="registration"
            type="text"
            {...registerSchedule("registration", { required: true })}
          />
          <DefaultLabel htmlFor="vehicleName" className="mt-2 mb-2">
            {t("account.vehicleName")}
          </DefaultLabel>
          <DefaultInput
            placeholder={t("account.enterName")}
            id="vehicleName"
            type="text"
            {...registerSchedule("vehicleName", { required: true })}
          />
          <DefaultLabel htmlFor="captainEmail" className="mt-2 mb">
            {t("account.captainEmail")}
          </DefaultLabel>
          <div className="mb-2 text-xs text-gray-500">
            {t("account.captainEmailMessage")}
          </div>
          <DefaultInput
            placeholder={t("account.enterEmail")}
            id="captainEmail"
            type="email"
            {...registerSchedule("captainEmail", {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t("account.enterEmail"),
              },
            })}
          />
          <DefaultLabel htmlFor="extraMarkerPk" className="mt-2 mb">
            {t("account.selectSecondarySpaceTitle")}
          </DefaultLabel>
          <div className="mb-2 text-xs text-gray-500">
            {t("account.extraMarkerMessage")}
          </div>

          <div className="w-full flex flex-row items-center gap-4">
            {!!extraMarker && (
              <DefaultButton
                value={t("account.removeExtraMarker")}
                variant={Variant.outlineRed}
                className="w-full"
                type="button"
                onClick={() => {
                  setValue("extraMarkerPk", NaN);
                }}
              />
            )}
            <DefaultButton
              value={t(
                extraMarker
                  ? "account.changeSecondarySpace"
                  : "account.selectSecondarySpace"
              )}
              variant={Variant.outline}
              className="w-full"
              loading={isLoading}
              type="button"
              onClick={() => {
                setShowExtraSelect(true);
                setShowCreateSchedule(false);
              }}
            />
          </div>

          <DefaultLabel htmlFor="length" className="mt-2 mb-1">
            {t("account.length")} ({t("account.metres")})
          </DefaultLabel>
          <DefaultInput
            placeholder={t("account.length")}
            id="length"
            type="number"
            {...registerSchedule("length", { required: true })}
          />
          <DefaultLabel htmlFor="price" className="mt-2 mb">
            {t("account.priceTax", { count: daysDifference })}
          </DefaultLabel>
          {!isPriceDirty && (
            <div className="text-sm text-gray-600 mb-2">
            {lineItems.map(i => (
              <div key={i.name} className="flex flex-row justify-between items.center mt-1">
                <div>{i.name}</div>
                <div>EUR {i.value}</div>
              </div>
            ))}
          </div>
          )}
          <DefaultInput
            placeholder={t("account.enterPrice")}
            id="price"
            type="number"
            step="0.01"
            {...registerSchedule("price", { required: true })}
          />
          <div
            className=" mt-4 text-md text-orange-500 bg-orange-100 p-4 rounded"
            dangerouslySetInnerHTML={{
              __html: t("account.addEntryMessage", {
                length: selectedMarker?.length,
                width: selectedMarker?.width,
                depth: selectedMarker?.depth,
                arrival: formatDate(
                  parseISO(searchData.start_datetime),
                  "PPPpp"
                ),
                departure: formatDate(
                  parseISO(searchData.end_datetime),
                  "PPPpp"
                ),
              }),
            }}
          />

          <div className="flex flex-row justify-end items-center mt-6">
            <DefaultButton
              value={t("account.addEntry")}
              variant={Variant.primary}
              disabled={!isValidSchedule}
              className="ml-4"
              type="submit"
              loading={isCreatingSchedule}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Index;
