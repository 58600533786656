interface Price {
  length: number;
  monohull: number;
  catamaran: number;
}
interface Place {
  readonly pk: number;
  name: string;
  prices: Price[];
  taxRate: number;
}

interface Marker {
  readonly pk: number;
  name: string;
  length: number;
  width: number;
  depth: number;
  available: boolean;
  position: {
    latitude: number;
    longitude: number;
  };
}

interface MarkerSchedule {
  readonly pk: number;
  startDatetime: string;
  endDatetime: string;
  price: number;
  registration: string;
  vehicleName: string;
  extraMarkerPk: number;
  readonly paid: boolean;
  readonly placeItemPk: number;
  readonly markerPk: number;
  readonly documentCategoriesAvailable: PlaceDocumentCategory[];
}

enum PlaceDocumentCategory {
  navigation = "NAV",
  ownership = "OWN",
  liability = "LIA",
  other = "OTH",
}

interface PlaceDocument {
  readonly pk: number;
  file: File | string;
  name: string;
  createdAt: string;
  mime: string;
  category: PlaceDocumentCategory;
}

interface PlaceDiscount {
  readonly pk: number;
  name: string;
  percentage: number;
  days: number;
  dayStart: number;
  dayEnd: number;
  monthStart: number;
  monthEnd: number;
}

interface Pagination<T> {
  next?: string;
  previous?: string;
  results: T[];
}

export type { Pagination, Place, Marker, MarkerSchedule, PlaceDocument, PlaceDiscount };
export { PlaceDocumentCategory };
