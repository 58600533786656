import { type Middleware } from "redux";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import { setToken } from "../store/members";

const rtkQueryErrorLogger: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    const status = action.payload?.originalStatus || action.payload?.status;
    if (isRejectedWithValue(action) && status >= 500) {
      // It crashed...
    } else if (status === 401 || status === 403) {
      dispatch(setToken(""));
    }

    return next(action);
  };

export { rtkQueryErrorLogger };
